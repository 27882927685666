export const ROUTES = {
  DASHBOARD: '/',
  SECRETARY: '/secretary',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  CONFIRMATION: '/confirmation',
  RESCHEDULE: '/reschedule',
  CLINICAL_TRIALS: '/clinical-trials',
  PATIENT_SUPPORT_PROGRAM: '/patient-support-program',
  HELP: '/help',
  ONBOARDING: '/onboarding',
  ANALYTICS: '/analytics',
  PATIENTS: {
    LIST: '/patients/list',
    RECORDS: {
      key: '/patients/:id/records',
      parse: (id: number | string) => `/patients/${id}/records`
    },
    BCMAP: {
      key: '/patients/:id/cspmap',
      parse: (id: number | string, type?: string) => `/patients/${id}/cspmap${type ? `?type=${type}` : ''}`
    },
    TAVR: {
      key: '/patients/:id/tavr',
      parse: (id: number | string, type?: string) => `/patients/${id}/tavr${type ? `?type=${type}` : ''}`
    },
    NALAGEN: {
      key: '/patients/:id/nalagen',
      parse: (id: number | string, type?: string) => `/patients/${id}/nalagen${type ? `?type=${type}` : ''}`
    }
  },
  PHR: {
    key: '/phr/:id',
    parse: (id: number | string, patientId: number | string) => `/phr/${id}?patient=${patientId}`,
    CF4: {
      key: '/phr/:id/cf4',
      parse: (id: number | string, patientId: number | string) => `/phr/${id}/cf4?patient=${patientId}`
    }
  },
  RESETPASSWORD: '/reset-password',
  PROFILE: '/profile',
  BILLING: '/billing',
  APPOINTMENT: {
    HOME: '/appointments',
    PRE_CONSULTATION: '/appointment/pre-consultation/:id',
    PATIENT: {
      NEW: '/appointment/patient/new',
      NEW_WITH_STAGED: {
        key: '/appointment/patient/new',
        parse: (
          id: number,
          firstName: string,
          middleName: string,
          lastName: string,
          gender: string,
          birthDate: string,
          email: string,
          contactNumber: string,
          hasEconsult: boolean,
          phrType: string
        ) =>
          `/appointment/patient/new?stagedId=${id}&firstName=${firstName}&middleName=${middleName}&lastName=${lastName}&gender=${gender}&birthDate=${birthDate}&email=${email}&contactNumber=${contactNumber}&hasEconsult=${hasEconsult}&phr_type=${phrType}`
      }
    },
    PARSE: {
      PRE_CONSULTATION: (patientId: number | string) => `/appointment/pre-consultation/${patientId}`
    }
  },
  PATIENT: {
    BASE: '/patients/',
    NEW: '/patients/new',
    EDIT: '/patients/edit/:id',
    PARSE: {
      EDIT: (patientId: number | string) => `/patients/edit/${patientId}`
    }
  },
  BCMAP: {
    BASE: '/cspmap',
    LOGIN: '/cspmap/login',
    SIGNUP: '/cspmap/signup',
    CONFIRMATION: '/cspmap/confirmation'
  }
  // TMC: {
  //   BASE: '/tmc',
  //   LOGIN: '/tmc/login',
  //   SIGNUP: '/tmc/signup'
  // }
};
