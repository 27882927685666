import React from 'react';

export const AsyncAuth = React.lazy(() => import('pages/Auth/Auth'));

export const AsyncAuthBCMAP = React.lazy(() => import('pages/Auth/AuthBCMAP'));

export const AsyncAuthTMC = React.lazy(() => import('pages/Auth/AuthTMC'));

export const AsyncResetPassword = React.lazy(() => import('pages/Auth/ResetPassword'));

export const AsyncDashboard = React.lazy(() => import('pages/Dashboard/Dashboard'));

export const AsyncOnboarding = React.lazy(() => import('pages/Profile/Onboarding'));

export const AsyncProfile = React.lazy(() => import('pages/Profile/Profile'));

export const AsyncAppointments = React.lazy(() => import('pages/Dashboard/Appointments'));

export const AsyncAppointmentPreConsultation = React.lazy(() => import('pages/Appointments/PreConsultation'));

export const AsyncNewPatientFromPatientList = React.lazy(() => import('pages/PHR/patients/NewPatientFromPatientList'));

export const AsyncPatientList = React.lazy(() => import('pages/PHR/patients/List'));

export const AsyncPatientRecords = React.lazy(() => import('pages/PHR/patients/Records'));

export const AsyncPatientBCMAP = React.lazy(() => import('pages/BCMAP/BCMAPForm'));

export const AsyncPatientTAVR = React.lazy(() => import('pages/TAVR/TAVRForm'));

export const AsyncPatientNalagen = React.lazy(() => import('pages/Nalagen/NalagenForm'));

export const AsyncNewPatientFromAppointment = React.lazy(() => import('pages/Appointments/NewPatientFromAppointment'));

export const AsyncEditPatientProfile = React.lazy(() => import('pages/Patient/EditPatientProfile'));

export const AsyncPHRForm = React.lazy(() => import('pages/PHR/encounter/Form'));

export const AsyncPHRCF4 = React.lazy(() => import('pages/CF4/CF4Form'));

export const AsyncReschedule = React.lazy(() => import('pages/Appointments/Reschedule'));

// export const AsyncAnalytics = React.lazy(() => import('pages/Analytics/AnalyticsSection'));

export const AsyncHelp = React.lazy(() => import('pages/Help'));
