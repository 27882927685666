import i18n from 'i18n';
import { KeyValue } from 'models';
import { _BirthWeight, _CoMorbidConditions, _Complications, _FamilyHistoryDetails, _Weights } from 'models/endpoint';

export const GENDER_OPTIONS = [
  { id: 'male', value: i18n.t('male') },
  { id: 'female', value: i18n.t('female') }
];
export const CONTACT_NUMBER_OPTIONS = [
  { type: 'mobile', value: i18n.t('mobile') },
  { type: 'landline', value: i18n.t('landline') }
];
export const NON_EMPTY_VALIDATOR = /([^\s])/;
export const EMAIL_VALIDATOR = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const NUMBER_VALIDATOR = /^-?\d+$/;
export const DOCTOR_IDENTIFICATION_OPTIONS = ['PRC No.', 'PHIC Accredited No.', 'S2 No.', 'PTR No.'].map(identifier => {
  return { type: identifier, value: identifier };
});

export const MALE = 'male';
export const FEMALE = 'female';

export const GENDER = {
  male: 'Male',
  female: 'Female'
} as { [key: string]: string };

export const ENCOUNTER_TYPE_OPTIONS = [
  { id: 'Phrs::TypeStandard', value: i18n.t('standard', { ns: 'encounter' }) },
  { id: 'Phrs::TypeCardiology', value: i18n.t('cardiology', { ns: 'encounter' }) },
  { id: 'Phrs::TypeEndocrinology', value: i18n.t('endocrinology', { ns: 'encounter' }) },
  { id: 'Phrs::TypeDiabetology', value: i18n.t('diabetology', { ns: 'encounter' }) },
  { id: 'Phrs::TypeOncology', value: i18n.t('oncology', { ns: 'encounter' }) },
  { id: 'Phrs::TypeBciOncology', value: i18n.t('bci_oncology', { ns: 'encounter' }) },
  { id: 'Phrs::TypeRheumatology', value: i18n.t('rheumatology', { ns: 'encounter' }) },
  { id: 'Phrs::TypePulmonology', value: i18n.t('pulmonology', { ns: 'encounter' }) },
  { id: 'Phrs::TypePediatric', value: i18n.t('pediatrics', { ns: 'encounter' }) },
  { id: 'Phrs::TypeActNow', value: i18n.t('actNow', { ns: 'encounter' }) },
  { id: 'Phrs::TypeEnt', value: i18n.t('ent', { ns: 'encounter' }) },
  { id: 'Phrs::TypeOphthalmology', value: i18n.t('ophthalmology', { ns: 'encounter' }) },
  { id: 'Phrs::TypeOb', value: i18n.t('ob', { ns: 'encounter' }) },
  { id: 'Phrs::TypeGyne', value: i18n.t('gyne', { ns: 'encounter' }) }
];

export enum EncounterType {
  Standard = 'Phrs::TypeStandard',
  Cardiology = 'Phrs::TypeCardiology',
  Endocrinology = 'Phrs::TypeEndocrinology',
  Diabetology = 'Phrs::TypeDiabetology',
  Oncology = 'Phrs::TypeOncology',
  HPB = 'Phrs::TypeHepatoPancreatoBiliary',
  Rheumatology = 'Phrs::TypeRheumatology',
  BCI = 'Phrs::TypeBciOncology',
  Pulmonology = 'Phrs::TypePulmonology',
  Pediatrics = 'Phrs::TypePediatric',
  Service = 'Phrs::TypeService',
  ActNow = 'Phrs::TypeActNow',
  ENT = 'Phrs::TypeEnt',
  Ophthalmology = 'Phrs::TypeOphthalmology',
  Ob = 'Phrs::TypeOb',
  Gyne = 'Phrs::TypeGyne'
}

export enum BookedByType {
  MedRep = 'med_rep',
  Patient = 'patient'
}

export const BRONCHIAL_ASTHMA_STRING = 'Bronchial Asthma';

export const STANDARD_ENCOUNTER = 'Phrs::TypeStandard';
export const ONCOLOGY_ENCOUNTER = 'Phrs::TypeOncology';
export const RHEUMATOLOGY_ENCOUNTER = 'Phrs::TypeRheumatology';
export const ENDOCRINOLOGY_ENCOUNTER = 'Phrs::TypeEndocrinology';
export const DIABETOLOGY_ENCOUNTER = 'Phrs::TypeDiabetology';
export const PEDIATRICS_ENCOUNTER = 'Phrs::TypePediatric';
export const HPB_ENCOUNTER = 'Phrs::TypeHepatoPancreatoBiliary';
export const BCI_ENCOUNTER = 'Phrs::TypeBciOncology';
export const PULMONOLOGY_ENCOUNTER = 'Phrs::TypePulmonology';
export const SERVICE_ENCOUNTER = 'Phrs::TypePulmonology';
export const ENT_ENCOUNTER = 'Phrs::TypeENT';

export const BREAST_CANCER_DIAGNOSIS = 'Diagnosis::BreastCancer';
export const COLON_CANCER_DIAGNOSIS = 'Diagnosis::ColonCancer';
export const LIVER_CANCER_DIAGNOSIS = 'Diagnosis::LiverCancer';
export const LUNG_CANCER_DIAGNOSIS = 'Diagnosis::LungCancer';
export const BRONCHIAL_ASTHMA_DIAGNOSIS = 'Diagnosis::BronchialAsthma';

//Patient Profile
export const Religions = [
  'Roman Catholic',
  'Christian',
  'Muslim',
  'Islam',
  'Evangelicals (PCEC)',
  'Iglesia ni Cristo (INC)',
  'Non-Roman Catholic and Protestant (NCCP)',
  'Aglipayan',
  'Seventh-day Adventist',
  'Bible Baptist Church',
  'United Church of Christ in the Philippines',
  "Jehovah's Witnesses",
  'Protestant',
  'Church of Christ',
  'Jesus is Lord Church',
  'Tribal Religions',
  'United Pentecostal Church Inc. (UPCI)',
  'Baptist',
  'Philippine Independent Catholic Church',
  'Unión Espiritista Cristiana de Filipinas, Inc.',
  'Church of Jesus Christ of the Latter Day Saints',
  'Association of Fundamental Baptist Churches in the Philippines',
  'Evangelical Christian Outreach Foundation',
  'Convention of the Philippine Baptist Church',
  'Crusaders of the Divine Church of Christ Inc.',
  'Buddhist',
  'Lutheran Church of the Philippines',
  'Iglesia sa Dios Espiritu Santo Inc.',
  'Philippine Benevolent Missionaries Association',
  'Faith Tabernacle Church (Living Rock Ministries)',
  'Others',
  'Jewish',
  'None'
];

export const Ethnicity = [
  { id: 'Hispanic', value: i18n.t('Hispanic') },
  { id: 'American Indian or Alaskan Native', value: i18n.t('American Indian or Alaskan Native') },
  { id: 'Asian', value: i18n.t('Asian') },
  { id: 'Filipino', value: i18n.t('Filipino') },
  { id: 'Chinese', value: i18n.t('Chinese') },
  { id: 'Filipino-Chinese', value: i18n.t('Filipino-Chinese') },
  { id: 'Filipino-American', value: i18n.t('Filipino-American') },
  { id: 'Filipino- Indian', value: i18n.t('Filipino- Indian') },
  { id: 'Indian', value: i18n.t('Indian') },
  { id: 'Native Hawaiian or Other Pacific Islander', value: i18n.t('Native Hawaiian or Other Pacific Islander') },
  { id: 'Japanese', value: i18n.t('Japanese') },
  { id: 'Taiwanese', value: i18n.t('Taiwanese') },
  { id: 'Black or African American', value: i18n.t('Black or African American') },
  { id: 'White', value: i18n.t('White') },
  { id: 'Spanish', value: i18n.t('Spanish') },
  { id: 'Korean', value: i18n.t('Korean') },
  { id: 'Malaysian', value: i18n.t('Malaysian') },
  { id: 'Thai', value: i18n.t('Thai') },
  { id: 'Vietnamese', value: i18n.t('Vietnamese') },
  { id: 'Malay', value: i18n.t('Malay') },
  { id: 'Indonesian', value: i18n.t('Indonesian') },
  { id: 'Arab', value: i18n.t('Arab') },
  { id: 'Egyptian', value: i18n.t('Egyptian') },
  { id: 'French', value: i18n.t('French') },
  { id: 'German', value: i18n.t('German') },
  { id: 'British', value: i18n.t('British') },
  { id: 'Australian', value: i18n.t('Australian') },
  { id: 'Unknown', value: i18n.t('unknown') }
];

export const IndonesianEthnicity = [
  'Bali',
  'Banjar',
  'Batak',
  'Betawi',
  'Bugis',
  'Chinese',
  'Cirebon',
  'Dayak',
  'Gorontalo',
  'Jawa',
  'Madura',
  'Makassar',
  'Melayu',
  'Minahasa',
  'Minangkabau',
  'Nias',
  'Sasak',
  'Suku asal Aceh',
  'Suku asal Banten',
  'Suku asal Jambi',
  'Suku asal Kalimantan lainnya',
  'Suku asal Lampung',
  'Suku asal Maluku',
  'Suku asal Papua',
  'Suku asal Sulawesi lainnya',
  'Suku asal Sumatera lainnya',
  'Suku asal Sumatera Selatan',
  'Suku asal Nusa Tenggara Timur',
  'Suku asal Nusa Tenggara Barat',
  'Sunda'
];

export const BloodType = ['O+', 'O-', 'A+', 'A-', 'B+', 'B-', 'AB+', 'AB-'];
export const EducationalAttainment = ['Primary', 'Secondary', 'Vocational', 'Undergraduate', 'Graduate', 'Doctoral'];
export const MaritalStatus = ['Single', 'Married', 'Separated', 'Divorced', 'Widowed'];

export const IdentificationType = [
  'Philhealth ID',
  'Voter’s ID',
  'Passport No.',
  'Driver’s License',
  'Postal ID',
  'SSS Unified Multi-Purpose Identification (UMID)',
  'Tax Identification Number (TIN) Card',
  'Senior/PWD ID'
];

export const HANDEDNESS = [
  { id: 'left', value: i18n.t('left_handed', { ns: 'patient_profile' }) },
  { id: 'rigth', value: i18n.t('right_handed', { ns: 'patient_profile' }) },
  { id: 'ambidextrous', value: i18n.t('ambidextrous', { ns: 'patient_profile' }) }
];

//Disease Related History
export const WITH_OR_NONE = [i18n.t('With'), i18n.t('None')];
export const TUMOR_TYPE = [
  'Cholangiocarcinoma – Hilar',
  'Cholangiocarcinoma – Intrahepatic',
  'Colorectal liver metastases',
  'Focal nodular hyperplasia',
  'Hemangioma',
  'Hepatic adenoma',
  'Hepatoblastoma  ',
  'Hepatocellular carcinoma',
  'Nodular regenerative hyperplasia',
  'Non-colorectal liver metastases',
  'Sarcoma',
  'Other',
  'None'
];

//Patient Lifestyle
export const Cigarettes = [
  'Never smoked',
  'Former smoker',
  'Current every day smoker',
  'Heavy tobacco smoker',
  'Unknown if ever smoked',
  'Smoker, current status unknown',
  'Current some day smoker'
];

export const Alcohol = [
  'Does not drink alcohol',
  'Occasional drinker',
  'Current everyday drinker',
  'Abuse alcohol',
  'Former alcohol drinker',
  'Unknown'
];

export const ExerciseHabits = ['Daily', 'Often (3-5 times per week)', 'Seldom', 'Does not exercise'];

export const DietAndNutrition = [
  'Unhealthy',
  'Well balanced diet',
  'Vegetarian',
  'Pescetarian',
  'Low-carbohydrate',
  'Low-fat',
  'Low-sodium',
  'Low-protein',
  'Low-sugar',
  'High-sugar',
  'High-carbohydrate',
  'High-fat',
  'High-sodium',
  'High-protein',
  'Detox diet',
  'Unknown'
];

export const DrugLifestyle = [
  'Never used drugs',
  'Former drug user',
  'Exposed to drug user',
  'Current everyday drug use',
  'Heavy drug user',
  'Unknown if used drugs',
  'Drug user, current status unknown',
  'Current some day user'
];

export const ModeOfDelivery = ['', 'NSD', 'C-Section', 'Instrumental Delivery'];
export const MenstrualCycle = ['Regular', 'Irregular'];
export const Frequency = ['Heavy', 'Clots', 'Flooding'];

export enum PeekType {
  Push = 'push',
  Replace = 'replace',
  Hide = 'hide',
  Remove = 'remove'
}

export enum ModalType {
  Push = 'push',
  Replace = 'replace',
  Hide = 'hide',
  Remove = 'remove'
}

export const BCIGeneralItems = {
  general__consciousness: [
    {
      id: 'awake',
      value: 'Awake'
    },
    {
      id: 'coherent',
      value: 'Coherent'
    },
    {
      id: 'obtunded',
      value: 'Obtunded'
    }
  ],
  general__body_build: [
    {
      id: 'cachetic',
      value: 'Cachetic'
    },
    {
      id: 'hyposthenic',
      value: 'Hyposthenic'
    },
    {
      id: 'sthenic',
      value: 'Sthenic'
    },
    {
      id: 'hypersthenic',
      value: 'Hypersthenic'
    }
  ],
  general__ambulation: [
    {
      id: 'ambulates_without_support',
      value: 'Ambulates Without Support'
    },
    {
      id: 'ambulates_with_support',
      value: 'Ambulates With Support'
    },
    {
      id: 'wheelchair',
      value: 'Wheelchair'
    },
    {
      id: 'bedbound',
      value: 'Bedbound'
    }
  ],
  general__cardiorespiratory: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ]
};

export const BCISkinItems = {
  skin__color: [
    {
      id: 'pale',
      value: 'Pale'
    },
    {
      id: 'sallow',
      value: 'Sallow'
    },
    {
      id: 'yellow',
      value: 'Yellow'
    }
  ],
  skin__pigmentation: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'hypopigmentation',
      value: 'Hypopigmentation'
    },
    {
      id: 'hyperpigmentation',
      value: 'Hyperpigmentation'
    }
  ],
  skin__lesions: [
    {
      id: 'none',
      value: 'None'
    },
    {
      id: 'present',
      value: 'Present'
    }
  ]
};

export const BCIHeentItems = {
  heent__face: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'mass',
      value: 'Mass'
    }
  ],
  heent__left_eye: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    },
    {
      id: 'not_examined',
      value: 'Not Examined'
    }
  ],
  heent__right_eye: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    },
    {
      id: 'not_examined',
      value: 'Not Examined'
    }
  ],
  heent__left_ear: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    },
    {
      id: 'not_examined',
      value: 'Not Examined'
    }
  ],
  heent__right_ear: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    },
    {
      id: 'not_examined',
      value: 'Not Examined'
    }
  ],

  heent__nose__septum: [
    {
      id: 'midline',
      value: 'Midline'
    },
    {
      id: 'deviated',
      value: 'Deviated'
    }
  ],
  heent__nose__turbiinates: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'congested',
      value: 'Congested'
    }
  ],
  heent__nose__mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  heent__nose__discharge: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],

  heent__throat__mucosa: [
    {
      id: 'pink',
      value: 'Pink'
    },
    {
      id: 'pale',
      value: 'Pale'
    },
    {
      id: 'moist',
      value: 'Moist'
    },
    {
      id: 'dry',
      value: 'Dry'
    }
  ],
  heent__throat__ulsers: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  heent__throat__tonsils: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'enlarged',
      value: 'Enlarged'
    },
    {
      id: 'exudates',
      value: 'Exudates'
    },
    {
      id: 'mass',
      value: 'Mass'
    },
    {
      id: 'congestion',
      value: 'Congestion'
    }
  ],
  heent__throat__uvula: [
    {
      id: 'midline',
      value: 'Midline'
    },
    {
      id: 'displaced',
      value: 'Displaced'
    }
  ],
  heent__throat__tongue: [
    {
      id: 'midline',
      value: 'Midline'
    },
    {
      id: 'displaced',
      value: 'Displaced'
    }
  ],
  heent__throat__pharynx: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'exudates',
      value: 'Exudates'
    },
    {
      id: 'mass',
      value: 'Mass'
    },
    {
      id: 'congestion',
      value: 'Congestion'
    }
  ]
};

export const BCINeckItems = {
  neck__pulsations: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  neck__masses: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  neck__lymph_nodes: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ]
};

export const BCIBreastItems = {
  breast__left__nipple_excoriation: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  breast__left__nipple_discharge: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  breast__left__nipple_retraction: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  breast__left__skin: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'orange_peel',
      value: 'Orange Peel'
    },
    {
      id: 'ulcer',
      value: 'Ulcer'
    },
    {
      id: 'clean',
      value: 'Clean'
    },
    {
      id: 'with_discharge',
      value: 'With Discharge'
    }
  ],
  breast__left__mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  breast__left__axilla_mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  breast__right__nipple_excoriation: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  breast__right__nipple_discharge: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  breast__right__nipple_retraction: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  breast__right__skin: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'orange_peel',
      value: 'Orange Peel'
    },
    {
      id: 'ulcer',
      value: 'Ulcer'
    },
    {
      id: 'clean',
      value: 'Clean'
    },
    {
      id: 'with_discharge',
      value: 'With Discharge'
    }
  ],
  breast__right__mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  breast__right__axilla_mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ]
};

export const BCIThoraxLungsItems = {
  thorax_lungs__left__mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  thorax_lungs__left__tenderness: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  thorax_lungs__left__abnormality: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  thorax_lungs__left__breath_sounds: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'absent',
      value: 'Absent'
    },
    {
      id: 'increased',
      value: 'Increased'
    },
    {
      id: 'decreased',
      value: 'Decreased'
    }
  ],
  thorax_lungs__left__adventitious_sounds: [
    {
      id: 'none',
      value: 'None'
    },
    {
      id: 'wheezes',
      value: 'Wheezes'
    },
    {
      id: 'crackles',
      value: 'Crackles'
    },
    {
      id: 'ronchi',
      value: 'Ronchi'
    }
  ],
  thorax_lungs__right__mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  thorax_lungs__right__tenderness: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  thorax_lungs__right__abnormality: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  thorax_lungs__right__breath_sounds: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'absent',
      value: 'Absent'
    },
    {
      id: 'increased',
      value: 'Increased'
    },
    {
      id: 'decreased',
      value: 'Decreased'
    }
  ],
  thorax_lungs__right__adventitious_sounds: [
    {
      id: 'none',
      value: 'None'
    },
    {
      id: 'wheezes',
      value: 'Wheezes'
    },
    {
      id: 'crackles',
      value: 'Crackles'
    },
    {
      id: 'ronchi',
      value: 'Ronchi'
    }
  ]
};

export const BCICardiovascularItems = {
  cardiovascular__precordial_pulsations: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  cardiovascular__apex_beat: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'deviated',
      value: 'Deviated'
    }
  ],
  cardiovascular__heart_sounds: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    }
  ],
  cardiovascular__murmurs: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ]
};

export const BCIAbdomenItems = {
  abdomen__pulsations: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  abdomen__scars: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  abdomen__distension: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  abdomen__abdominal_wall_mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  abdomen__bowel_sounds: [
    {
      id: 'hypoactive',
      value: 'Hypoactive'
    },
    {
      id: 'normoactive',
      value: 'Normoactive'
    },
    {
      id: 'hyperactive',
      value: 'Hyperactive'
    }
  ],
  abdomen__tenderness: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  abdomen__hepatomegaly: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  abdomen__splenomegaly: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  abdomen__mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ]
};

export const BCIRectalItems = {
  rectal__inspection__excoriation: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  rectal__inspection__erythema: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  rectal__inspection__anal_tag: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  rectal__inspection__hemorrhoids: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  rectal__inspection__mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  rectal__digital__mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  rectal__digital__prostate: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'enlarged',
      value: 'Enlarged'
    }
  ],
  rectal__digital__tenderness: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  rectal__digital__color_of_stools: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    }
  ]
};

export const BCIGynecologicItems = {
  gynecologic__perineum__excoriation: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__perineum__erythema: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__perineum__mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],

  gynecologic__speculum__cervix_discharge: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__speculum__cervix_excoriation: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__speculum__cervix_mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__speculum__cervix_others: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__speculum__vagina_excoriation: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__speculum__vagina_erythema: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__speculum__vagina_mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__speculum__vagina_others: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],

  gynecologic__internal_examination__cervix_tenderness: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__internal_examination__mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__internal_examination__uterus_size: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'enlarged',
      value: 'Enlarged'
    }
  ],
  gynecologic__internal_examination__uterus_tenderness: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__internal_examination__adnexae_mass: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ],
  gynecologic__internal_examination__adnexae_tenderness: [
    {
      id: 'Yes',
      value: 'Yes'
    },
    {
      id: 'No',
      value: 'No'
    }
  ]
};

export const BCINeurologicalItems = {
  neurological__mental_status__consciousness: [
    {
      id: 'conscious',
      value: 'Conscious'
    },
    {
      id: 'stupor',
      value: 'Stupor'
    },
    {
      id: 'light_coma',
      value: 'Light Coma'
    },
    {
      id: 'deep_coma',
      value: 'Deep Coma'
    }
  ],
  neurological__mental_status__orientation: [
    {
      id: 'oriented',
      value: 'Oriented'
    },
    {
      id: 'disoriented',
      value: 'Disoriented'
    }
  ],

  neurological__motor__upper_extremities_left: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    }
  ],
  neurological__motor__upper_extremities_right: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    }
  ],
  neurological__motor__lower_extremities_left: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    }
  ],
  neurological__motor__lower_extremities_right: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    }
  ],

  neurological__coordination_involuntary_movements: [
    {
      id: 'absent',
      value: 'Absent'
    },
    {
      id: 'present',
      value: 'Present'
    }
  ],
  neurological__sensory: [
    {
      id: 'absent',
      value: 'Absent'
    },
    {
      id: 'present',
      value: 'Present'
    }
  ],
  neurological__reflexes__superficial: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    }
  ],
  neurological__reflexes__deep_tendon: [
    {
      id: 'normal',
      value: 'Normal'
    },
    {
      id: 'abnormal',
      value: 'Abnormal'
    }
  ]
};

export const BCIPhysicalExamSections = [
  'general',
  'skin',
  'heent',
  'neck',
  'breast',
  'thoraxLungs',
  'cardiovascular',
  'pulses',
  'abdomen',
  'muskuloskeletal',
  'rectal',
  'gynecologic',
  'neurological'
];

export const TEMPERATURE_UNIT: KeyValue[] = [{ id: 'c', value: '°C' }];

export const HEAD_CIRCUMFERENCE_UNIT: KeyValue[] = [
  { id: 'cm', value: i18n.t('cm', { ns: 'encounter' }) },
  { id: 'in', value: i18n.t('in', { ns: 'encounter' }) }
];

export const HEIGHT_UNIT: KeyValue[] = [
  { id: 'cm', value: i18n.t('cm', { ns: 'encounter' }) },
  { id: 'in', value: i18n.t('in', { ns: 'encounter' }) }
];

export const WEIGHT_UNIT: KeyValue[] = [
  { id: 'kg', value: i18n.t('kg', { ns: 'encounter' }) },
  { id: 'lb', value: i18n.t('lb', { ns: 'encounter' }) }
];

export const FBS_UNIT: KeyValue[] = [
  { id: 'mg/dL', value: 'mg/dL' },
  { id: 'mmol/L', value: 'mmol/L' }
];

export const BLOOD_SUGAR_UNIT: KeyValue[] = [
  { id: 'mmol/L', value: 'mmol/L' },
  { id: 'mg/dL', value: 'mg/dL' }
];

export const RGT_FGT_OGTT: KeyValue[] = [
  { id: 'mmol/L', value: 'mmol/L' },
  { id: 'mg/dL', value: 'mg/dL' }
];

export const BLOOD_SUGAR_TYPE: KeyValue[] = [
  { id: 'HbA1c', value: 'HbA1c' },
  { id: 'RGT', value: 'RGT' },
  { id: 'FGT', value: 'FGT' },
  { id: 'OGGT', value: 'OGGT' }
];

export const MASS_LATERALITY: KeyValue[] = [
  { id: 'right', value: 'Right' },
  { id: 'left', value: 'Left' },
  { id: 'both', value: 'Both' }
];

export const YES_NO_OPTIONS: KeyValue[] = [
  { id: 'Yes', value: i18n.t('Yes') },
  { id: 'No', value: i18n.t('No') }
];

export const YES_NO_UNKNOWN_OPTIONS: KeyValue[] = [
  { id: 'Yes', value: i18n.t('Yes') },
  { id: 'No', value: i18n.t('No') },
  { id: 'unknown', value: 'Unknown' }
];

export const YES_NO_NOT_ASSESSED_OPTION: KeyValue[] = [
  { id: 'Yes', value: i18n.t('Yes') },
  { id: 'No', value: i18n.t('No') },
  { id: 'not_assessed', value: 'Not Assessed' }
];

export const RATE_1_OF_5: KeyValue[] = [
  { id: '1', value: '1' },
  { id: '2', value: '2' },
  { id: '3', value: '3' },
  { id: '4', value: '4' },
  { id: '5', value: '5' }
];

export const GRADE_OPTIONS: KeyValue[] = [
  { id: '1', value: 'Grade 1' },
  { id: '2', value: 'Grade 2' },
  { id: '3', value: 'Grade 3' }
];

export const POS_NEG_OPTIONS: KeyValue[] = [
  { id: 'Positive', value: 'Positive' },
  { id: 'Negative', value: 'Negative' }
];

export const POS_NEG_OPTIONS_EXTENDED: KeyValue[] = [
  { id: 'Positive', value: 'Positive' },
  { id: 'Negative', value: 'Negative' },
  { id: 'Equivocal', value: 'Equivocal' }
];

export const TUMOR_OPTIONS: KeyValue[] = [
  { id: 'Tis', value: 'Tis' },
  { id: 'T0', value: 'T0' },
  { id: 'T1', value: 'T1' },
  { id: 'T2', value: 'T2' },
  { id: 'T3', value: 'T3' },
  { id: 'T4', value: 'T4' }
];

export const LYMPH_NODES_OPTIONS: KeyValue[] = [
  { id: 'N0', value: 'N0' },
  { id: 'N1', value: 'N1' },
  { id: 'N2', value: 'N2' },
  { id: 'N3', value: 'N3' }
];

export const METASTASIS_OPTIONS: KeyValue[] = [
  { id: 'M0', value: 'M0' },
  { id: 'M1', value: 'M1' }
];

export const CLINICAL_STAGE_OPTIONS: KeyValue[] = [
  { id: '0', value: '0' },
  { id: 'I', value: 'I' },
  { id: 'IIA', value: 'IIA' },
  { id: 'IIB', value: 'IIB' },
  { id: 'IIIA', value: 'IIIA' },
  { id: 'IIIB', value: 'IIIB' },
  { id: 'IIIC', value: 'IIIC' },
  { id: 'IV', value: 'IV' }
];

export const THERAPY_OPTIONS: KeyValue[] = [
  { id: 'NeoAdjuvant', value: 'NeoAdjuvant' },
  { id: 'Adjuvant', value: 'Adjuvant' },
  { id: 'Both', value: 'Both' },
  { id: 'Neither', value: 'Neither' }
];

export const DRUG_TYPE_OPTIONS: KeyValue[] = [
  { id: 'Cytotoxic', value: 'Cytotoxic' },
  { id: 'MonoclonalAntibody', value: 'Monoclonal Antibody' },
  { id: 'ImmunoTherapy', value: 'Immuno Therapy' },
  { id: 'TargetedTherapy', value: 'Targeted Therapy' },
  { id: 'AntibodyDrugConjugate', value: 'Antibody-drug Conjugate' },
  { id: 'Others', value: 'Others' }
];

export const DRUG_RESPONSE = [
  { id: 'NED', value: 'No Evidence of Disease (NED)' },
  { id: 'CR', value: 'Complete Response (CR)' },
  { id: 'PR', value: 'Partial Response (PR)' },
  { id: 'SD', value: 'Stable Disease (SD)' },
  { id: 'PD', value: 'Progressive Disease (PD)' }
];

export const SEVERITY_GRADE = [
  { id: 'grade_1', value: 'Grade 1' },
  { id: 'grade_2', value: 'Grade 2' },
  { id: 'grade_3', value: 'Grade 3' },
  { id: 'grade_4', value: 'Grade 4' },
  { id: 'grade_5', value: 'Grade 5' }
];

export const ACCESS_SITES = [
  {
    id: 'ASMGH-WV',
    value: 'Angel Salazar Memorial General Hospital',
    address: 'Tobias A. Fornier Street, San Jose de Buenavista, Antique, 5700 Western Visayas'
  },
  {
    id: 'ARMMC-MM',
    value: 'Amang Rodriguez Memorial Medical Center, Metro Manila',
    address: 'Sumulong Highway Sto. Nino, Marikina, 1800 Metro Manila'
  },
  {
    id: 'AHMC-MM',
    value: 'Asian Hospital Medical Center, Metro Manila',
    address: '2205 Civic Dr, Alabang, Muntinlupa, 1780 Metro Manila'
  },
  {
    id: 'VLGH-MM',
    value: 'Victoriano Luna General Hospital',
    address: 'V. Luna Ave, Diliman, Quezon City, 1100 Metro Manila'
  },
  {
    id: 'EAMC-MM',
    value: 'East Avenue Medical Center, Metro Manila',
    address: 'East Ave, Diliman, Quezon City, 1100 Metro Manila'
  },
  {
    id: 'JRRMMC-MM',
    value: 'Jose R. Reyes Memorial Medical Center, Metro Manila',
    address: 'Rizal Ave, Santa Cruz, Manila, Metro Manila'
  },
  { id: 'RMC-MM', value: 'Rizal Medical Center, Metro Manila', address: 'Pasig Blvd, Pasig, 1600 Metro Manila' },
  {
    id: 'PGH-MM',
    value: 'Philippine General Hospital, Metro Manila',
    address: 'Taft Ave, Ermita, Manila, 1000 Metro Manila'
  },
  {
    id: 'NKATI-MM',
    value: 'National Kidney and Transplant Institute, Metro Manila',
    address: 'East Ave, Diliman, Quezon City, 1101 Metro Manila'
  },
  {
    id: 'PCMC-MM',
    value: 'Philippine Children’s Medical Center, Metro Manila',
    address: 'Quezon Avenue, corner Agham Rd, Diliman, Quezon City, 1101 Metro Manila'
  },
  {
    id: 'NCH-MM',
    value: 'National Children’s Hospital, Metro Manila',
    address: '264 E Rodriguez Sr. Ave, New Manila, Quezon City, 1113 Metro Manila'
  },

  { id: 'RIMC-I', value: 'Region I Medical Center, Ilocos', address: 'Arellano Street, Dagupan, 2400 Pangasinan' },
  {
    id: 'ITRMC-I',
    value: 'Ilocos Training and Regional Medical Center, Ilocos',
    address: 'MacArthur Highway, San Fernando, 2500 La Union'
  },
  {
    id: 'MMMHMC-I',
    value: 'Mariano Marcos Memorial Hospital and Medical Center, Ilocos',
    address: 'Brgy, 6, Ilocos Norte'
  },

  {
    id: 'CVMC-CV',
    value: 'Cagayan Valley Medical Center, Cagayan Valley',
    address: 'Pan-Philippine Hwy, Carig Regional Center, Tuguegarao, Cagayan'
  },

  {
    id: 'DJBLMMC-CL',
    value: 'Dr. Jose B. Lingad Memorial Medical Center, Central Luzon',
    address: '2000 MacArthur Highway, San Fernando, Pampanga'
  },
  {
    id: 'BGHMC-CL',
    value: 'Bataan General Hospital and Medical Center, Central Luzon',
    address: 'Manahan St, TenejeroBalanga City, 2100 Bataan'
  },

  { id: 'BMC-C', value: 'Batangas Medical Center, CALABARZON', address: 'Bihi Road, KumintangIbaba, Batangas' },

  // { id: 'MIMA', value: 'MIMAROPA', address: '' },
  { id: 'BRTATH-B', value: 'Bicol Regional Training and Teaching Hospital', address: 'Legazpi City, Albay' },
  {
    id: 'BMC-B',
    value: 'Bicol Medical Center, Bicol',
    address: 'BMC Rd., Concepcion Pequeña, Naga City, Camarines Sur 4400'
  },

  {
    id: 'BGHAMC-CA',
    value: 'Baguio General Hospital and Medical Center, Cordillera Administrative',
    address: 'Baguio General Hospital & Medical Center, Governor Pack Road, Baguio, 2600 Benguet'
  },

  {
    id: 'WVMC-WV',
    value: 'Western Visayas Medical Center, Western Visayas',
    address: 'Q. Abeto St, Mandurriao, Iloilo City, 5000 Iloilo'
  },
  {
    id: 'CLMMRH-WV',
    value: 'Corazon Locsin Montelibano Memorial Regional Hospital, Western Visayas',
    address: 'Lacson St, Bacolod, 6100 Negros Occidental'
  },

  {
    id: 'VSMMC-CV',
    value: 'Vicente Sotto Memorial Medical Center, Central Visayas',
    address: 'B. Rodriguez St, Sambag II, Cebu City, Cebu'
  },

  // { id: 'EVIS', value: 'Eastern Visayas', address: '' },
  {
    id: 'ZPEN',
    value: 'Zamboanga City Medical Center, Zamboanga Peninsula',
    address: 'Zamboanga del Sur PH Dr Evangelista Street, Sta. Catalina Rd, Zamboanga, 7000'
  },

  {
    id: 'NMMC-NM',
    value: 'Northern Mindanao Medical Center, Northern Mindanao',
    address: 'Capitol Rd, Cagayan de Oro, 9000 Misamis Oriental'
  },

  { id: 'DRMC-D', value: 'Davao Regional Medical Center, Davao', address: 'Tagum City, Davao del Norte' },
  {
    id: 'SPMC-D',
    value: 'Southern Philippines Medical Center, Davao',
    address: 'Jose P Laurel Avenue Bajada, Buhangin, Davao City, 8000 Davao del Sur'
  },
  {
    id: 'CRAMC-S',
    value: 'Cotabato Regional and Medical Center, Soccksargen',
    address: 'Rosary Heights,, 10 Sinsuat Ave, Region 12, Cotabato City, 9600 Maguindanao'
  },
  {
    id: 'CS-S',
    value: 'Cotabato Sanitarium',
    address: 'Barangay Pinaring, Quezon Ave, Sultan Kudarat, Maguindanao'
  },
  {
    id: 'EVMC-L',
    value: 'Eastern Visayas Medical Center',
    address: 'Magsaysay Blvd, Downtown, Tacloban City, Leyte'
  },
  {
    id: 'AMC-A',
    value: 'Antique Medical Center',
    address: 'Iloilo-Antique Road, San Jose de Buenavista, Antique'
  },
  {
    id: 'DRPJGMRMC-NE',
    value: 'Dr. Paulino J. Garcia Memorial Research and Medical Center',
    address: 'Mabini Street Extension, Cabanatuan City, 3100 Nueva Ecija'
  }
];

export const BREAST_RELATED_SURGERY = [
  { id: '19000', value: 'Puncture aspiration of cyst of breast;' },
  { id: '19100', value: 'Biopsy of breast; needle core' },
  { id: '19101', value: 'Biopsy of breast; incisional' },
  {
    id: '19120',
    value:
      'Excision of cyst, fibroadenoma, or other benign or malignant tumor aberrant breast tissue, duct lesion or nipple lesion (except 19140), male or female, one or more lesions'
  },
  {
    id: '19125',
    value: 'Excision of breast lesion identified by preoperative placement of radiological marker; single lesion'
  },
  {
    id: '19340',
    value: 'Immediate insertion of breast prosthesis following mastopexy, mastectomy or in reconstruction'
  },
  { id: '19342', value: 'Delayed insertion of breast prosthesis following mastopexy, mastectomy or in reconstruction' },
  {
    id: '19357',
    value: 'Breast reconstruction, immediate or delayed, with tissue expander, including subsequent expansion'
  },
  { id: '19361', value: 'Breast reconstruction with latissimus dorsi flap, with or without prosthetic implant' },
  { id: '19364', value: 'Breast reconstruction with free flap' },
  { id: '19366', value: 'Breast reconstruction with other technique' },
  {
    id: '19367',
    value:
      'Breast reconstruction with transverse rectus abdominis myocutaneous flap (TRAM), single pedicle, including closure of donor site;'
  },
  {
    id: '19369',
    value:
      'Breast reconstruction with transverse rectus abdominis myocutaneous flap (TRAM), double pedicle, including closure of donor site'
  },
  { id: '19370', value: 'Open periprosthetic capsulotomy, breast' },
  { id: '19371', value: 'Periprosthetic capsulectomy, breast' },
  {
    id: '76095',
    value:
      'Stereotactic localization guidance for breast biopsy or needle placement (e.g., for wire localization or for injection), one or more lesion, radiological supervision and interpretation'
  },
  {
    id: '76096',
    value:
      'Mammographic guidance for needle placement, breast (e.g., for wire localization or for injection), each lesion, radiological supervision and interpretation'
  },
  {
    id: '19110',
    value: 'Nipple exploration, w/ or w/o excision of a solitary lactiferous duct or a papilloma lactiferous duct'
  },
  { id: '19350', value: 'Nipple/areola reconstruction' },
  { id: '19140', value: 'Mastectomy for gynecomastia' },
  { id: '19160', value: 'Mastectomy, partial;' },
  { id: '19162', value: 'Mastectomy, partial; with axillary lymphadenectomy' },
  { id: '19180', value: 'Mastectomy, simple, complete' },
  { id: '19182', value: 'Mastectomy, subcutaneous' },
  { id: '19200', value: 'Mastectomy, radical, icnluding pectoral muscles, axillary lymph nodes' },
  {
    id: '19220',
    value:
      'Mastectomy, radical, including pectoral muscles, axillary and internal mammary lymph nodes (Urban type operation)'
  },
  {
    id: '19240',
    value:
      'Mastectomy, modified radical, including axillary lymph nodes, w/ or w/o pectoralis minor muscle, but excluding pectoralis major muscle'
  },
  { id: '19020', value: 'Mastotomy w/ exploration or drainage of abscess, deep' }
];

export const YES_NO_UNK_NA_OPTIONS: KeyValue[] = [
  { id: 'yes', value: 'Yes' },
  { id: 'no', value: 'No' },
  { id: 'unknown', value: 'Unknown' },
  { id: 'na', value: 'NA' }
];

export const ITEMS: KeyValue[] = [
  { id: 'Positive', value: 'Positive(+)' },
  { id: 'Negative', value: 'Negative(-)' },
  { id: 'Equivocal', value: 'Equivocal' }
];

export const TUMOR_MARKER_TYPE_OPTIONS: KeyValue[] = [
  { id: 'cea', value: 'CEA' },
  { id: 'afp', value: 'AFP' },
  { id: 'ca199', value: 'CA 19-9' },
  { id: 'ca125', value: 'CA 125' },
  { id: 'bhcg', value: 'BHCG' },
  { id: 'ldh', value: 'LDH' },
  { id: 'esr', value: 'ESR' },
  { id: 'other', value: 'Other' }
];

export const ECOG_SELECT = [
  { id: '0', value: '0' },
  { id: '1', value: '1' },
  { id: '2', value: '2' },
  { id: '3', value: '3' },
  { id: '4', value: '4' },
  { id: '5', value: '5' }
];

export enum StagingFormType {
  Liver = 'PhrStagings::Liver',
  Pancreatic = 'PhrStagings::Pancreatic',
  Gallbladder = 'PhrStagings::Gallbladder',
  Breast = 'PhrStagings::Breast',
  CervixUteri = 'PhrStagings::CervixUteri',
  Lung = 'PhrStagings::Lung',
  Prostate = 'PhrStagings::Prostate',
  CervicalLymphNodes = 'PhrStagings::CervicalLymphNode',
  MusculoskeletalSystem = 'PhrStagings::MusculoskeletalSystem',
  Endometrial = 'PhrStagings::CorpusUteri',
  Oropharyngeal = 'PhrStagings::Oropharyngeal',
  Nasopharyngeal = 'PhrStagings::Nasopharyngeal',
  OralCavity = 'PhrStagings::OralCavityCancer',
  BrainAndSpinalCord = 'PhrStagings::BrainAndSpinalCord',
  Colorectal = 'PhrStagings::Colorectal',
  Kidney = 'PhrStagings::Kidney',
  ActNow = 'PhrStagings::TypeActNow',
  NasalCavity = 'PhrStagings::NasalCavity',
  LarynxGlottis = 'PhrStagings::LarynxGlottis',
  LarynxSubglottis = 'PhrStagings::LarynxSubglottis',
  LarynxSupraglottis = 'PhrStagings::LarynxSupraglottis',
  Ophthalmology = 'PhrStagings::Ophthalmology',
  ENT = 'PhrStagings::ENT'
}

export const STAGING_FORM_TYPE = [
  { id: 'Liver', value: i18n.t('liver', { ns: 'encounter' }) },
  { id: 'Gallbladder', value: i18n.t('gallbladder', { ns: 'encounter' }) },
  { id: 'Breast', value: i18n.t('breast', { ns: 'encounter' }) },
  { id: 'CervixUteri', value: i18n.t('cervix_uteri', { ns: 'encounter' }) },
  { id: 'Lung', value: i18n.t('lung', { ns: 'encounter' }) },
  { id: 'Prostate', value: i18n.t('prostate', { ns: 'encounter' }) },
  { id: 'MusculoskeletalSystem', value: 'Musculoskeletal System' },
  { id: 'CervicalLymphNodes', value: i18n.t('cervical_lymph_nodes', { ns: 'encounter' }) },
  { id: 'Endometrial', value: i18n.t('endometrial', { ns: 'encounter' }) },
  { id: 'Oropharyngeal', value: i18n.t('oropharyngeal', { ns: 'encounter' }) },
  { id: 'Nasopharyngeal', value: i18n.t('nasopharyngeal', { ns: 'encounter' }) },
  { id: 'Pancreatic', value: i18n.t('pancreatic', { ns: 'encounter' }) },
  { id: 'OralCavity', value: i18n.t('oral_cavity', { ns: 'encounter' }) },
  { id: 'BrainAndSpinalCord', value: 'Brain and Spinal Cord' },
  { id: 'Colorectal', value: 'Colorectal' },
  { id: 'NasalCavity', value: 'Nasal Cavity' },
  { id: 'LarynxGlottis', value: 'Larynx Glottis' },
  { id: 'LarynxSubglottis', value: 'Larynx Subglottis' },
  { id: 'LarynxSupraglottis', value: 'Larynx Supraglottis' },
  { id: 'Ophthalmology', value: 'Ophthalmology' },
  { id: 'ENT', value: 'ENT' }
];

export const LIST_FILTER = [
  { id: 'all', value: i18n.t('all_patients', { ns: 'appointment' }) },
  { id: 'clinic', value: i18n.t('per_clinic', { ns: 'appointment' }) },
  { id: 'watchlist', value: i18n.t('watch_list', { ns: 'appointment' }) }
];

export const MEDICAL_PROGRAM_FILTER = [
  { id: 'all', value: 'All' },
  {
    id: 'MedicalPrograms::TypeClinicalTrial',
    value: i18n.t('potentially_eligible_for_clinical_trial', { ns: 'patient_profile' })
  },
  {
    id: 'MedicalPrograms::TypePatientSupport',
    value: i18n.t('potentially_eligible_for_support_program', { ns: 'patient_profile' })
  }
];

export const PATIENT_FILTER_SUB_TERM = [
  { id: 'name', value: i18n.t('name', { ns: 'appointment' }) },
  { id: 'id', value: i18n.t('id', { ns: 'appointment' }) }
];

export const PATIENT_FILTER_SUB_TERM_WITH_CODE = [
  { id: 'name', value: i18n.t('name', { ns: 'appointment' }) },
  { id: 'id', value: i18n.t('id', { ns: 'appointment' }) },
  { id: 'code', value: i18n.t('code', { ns: 'appointment' }) }
];

export const PULMO_LEVEL_OF_CONTROL = [
  { id: 'uncontrolled', value: 'UNCONTROLLED' },
  { id: 'partly_controlled', value: 'PARTLY-CONTROLLED' },
  { id: 'well_controlled', value: 'WELL-CONTROLLED' }
];

export const DEFAULT_CO_MORBID: _CoMorbidConditions[] = [
  { name: 'Hypertension', value: false, maintenance: '' },
  { name: 'Heart Disease', value: false, maintenance: '' },
  { name: 'Diabetes', value: false, maintenance: '' },
  { name: 'Tuberculosis', value: false, maintenance: '' },
  { name: 'Allergic Rhinitis', value: false, maintenance: '' },
  { name: 'Skin Allergies', value: false, maintenance: '' },
  { name: 'Obesity', value: false, maintenance: '' }
];

export const DEFAULT_FAMILY_HISTORY: _FamilyHistoryDetails[] = [
  { name: 'HPN', value: false, remarks: '' },
  { name: 'Stroke/CVA', value: false, remarks: '' },
  { name: 'DM', value: false, remarks: '' },
  { name: 'Thyroid Dse.', value: false, remarks: '' },
  { name: 'Bronchial Asthma', value: false, remarks: '' },
  { name: 'Cardiovascular', value: false, remarks: '' },
  { name: 'Breast cancer (BRCA1/2, p53, PTEN, or other gene mutation)', value: false, remarks: '' },
  { name: 'Other Cancers', value: false, remarks: '' }
];

export const DEFAULT_FAMILY_HISTORY_TRANSLATION: any = {
  hpn: 'HPN',
  stroke_cva: 'Stroke/CVA',
  dm: 'DM',
  thyroid_dse: 'Thyroid Dse.',
  bronchial_asthma: 'Bronchial Asthma',
  cardiovascular: 'Cardiovascular',
  other_cancers: 'Other Cancers'
};

export const DEFAULT_COMPLICATION_PLACEHOLDERS: any = {
  Retinopathy: i18n.t('pl_retinopathy_complications', { ns: 'patient_profile' }),
  Nephropathy: i18n.t('pl_nephropathy_complications', { ns: 'patient_profile' }),
  Neuropathy: i18n.t('pl_neuropathy_complications', { ns: 'patient_profile' }),
  'Coronary Artery Disease (CAD)': i18n.t('pl_coronary_artery_disease_CAD', { ns: 'patient_profile' }),
  'Peripheral Vascular Disease (PVD)': i18n.t('pl_peripheral_vascular_disease_PVD', { ns: 'patient_profile' }),
  'Cerebro Vascular Disease (CVD)': i18n.t('pl_cerebro_vascular_disease_CVD', { ns: 'patient_profile' })
};

export const DEFAULT_COMPLICATIONS: _Complications[] = [
  { name: 'Retinopathy', date_of_onset: null, value: false, remarks: '' },
  { name: 'Nephropathy', date_of_onset: null, value: false, remarks: '' },
  { name: 'Neuropathy', date_of_onset: null, value: false, remarks: '' },
  { name: 'Coronary Artery Disease (CAD)', date_of_onset: null, value: false, remarks: '' },
  { name: 'Peripheral Vascular Disease (PVD)', date_of_onset: null, value: false, remarks: '' },
  { name: 'Cerebro Vascular Disease (CVD)', date_of_onset: null, value: false, remarks: '' }
];

export const DEFAULT_GENERAL_MEDICAL_HISTORY: _CoMorbidConditions[] = [{ name: '', value: false, maintenance: '' }];

export const PATIENT_WEIGHT_DEFAULTS: _Weights = {
  weight: {
    highest: 0,
    lowest: 0
  },
  weight_unit: {
    highest: 'kg',
    lowest: 'kg'
  },
  weight_gained: 0,
  weight_gained_unit: 'kg',
  weight_date: null,
  remarks: ''
};

export const BIRTH_WEIGHT_DEFAULTS: _BirthWeight = {
  bw__g1: 0,
  bw__g2: 0,
  bw__g3: 0,
  bw__g1_unit: 'kg',
  bw__g2_unit: 'kg',
  bw__g3_unit: 'kg'
};

export const DEFAULT_IDENTIFICATION_NUMBERS = [{ id_type: IdentificationType[0], identifier: '' }];

export const DEFAULT_EMPLOYMENTS = [{ occupation: '', company_name: '', remarks: '', address: '' }];

export const DEFAULT_EDUCATIONS = [{ educational_attainment: '', institution_name: '', remarks: '' }];

export const DEFAULT_REFRACTION = [{ od: '', os: '', pd: '' }];

export enum PatientReferralType {
  PHR = 'PatientHealthRecord',
  User = 'User'
}

export enum PulmonologyFormSubType {
  Basic = 'PulmonologyBasicForm',
  Tuberculosis = 'Tuberculosis',
  Sleep = 'Sleep'
}

export const GENEXPERT_MTB_RIF = [
  { id: 'T', value: 'T' },
  { id: 'RR', value: 'RR' },
  { id: 'TI', value: 'TI' },
  { id: 'N', value: 'N' },
  { id: 'I', value: 'I' }
];

export const START_CAT_OPTION = [
  { id: 'I', value: 'I' },
  { id: 'Ia', value: 'Ia' },
  { id: 'II', value: 'II' },
  { id: 'IIa', value: 'IIa' }
];

export const SLEEP_EVALUATION_SNORING_OPTION = [
  { id: 'mild', value: 'MILD' },
  { id: 'moderate', value: 'MODERATE' },
  { id: 'loud', value: 'LOUD' },
  { id: 'very loud', value: 'VERY LOUD' }
];

export const SLEEP_EVALUATION_BREATHING_OPTION = [
  { id: 'stop breathing', value: 'STOP BREATHING' },
  { id: 'snorting', value: 'SNORTING' },
  { id: 'choking', value: 'CHOKING' }
];

export const getRateItems = (lowest: number, highest: number) => {
  let constants: KeyValue[] = [];
  for (let i = lowest; i <= highest; i++) {
    constants = [...constants, { id: `${i}`, value: `${i}` }];
  }
  return constants;
};

export const APPOINTMENT_FORMS_REJECT_REASONS = [
  { id: 'incomplete', value: 'Incomplete/Missing information' },
  { id: 'wrong_form', value: 'Wrong forms submitted' },
  { id: 'unsigned', value: 'Unsigned document/ Missing signature' },
  { id: 'mismatched_info', value: 'Mismatched information in portal vs submitted documents' },
  { id: 'corrupted', value: 'Corrupted file ' },
  { id: 'missing_attachment', value: 'Missing attachments ' },
  { id: 'invalid_document', value: 'Invalid Document ' }
];

export const VERIFY_OPTIONS = [
  { id: 'rejected', value: 'Reject' },
  { id: 'confirmed', value: 'Approve' }
];

export const CMAP_TYPE: KeyValue[] = [
  { id: 'Submissions::Bcmap', value: 'Breast' },
  { id: 'Submissions::Crcmap', value: 'Colon Rectum' },
  { id: 'Submissions::Lcmap', value: 'Lymphoma' },
  { id: 'Submissions::Lucmap', value: 'Lung' },
  { id: 'Submissions::Gcmap', value: 'Gyne' },
  { id: 'Submissions::Pcmap', value: 'Prostate' },
  { id: 'Submissions::Egcmap', value: 'Esophago-gastric' },
  { id: 'Submissions::Hncmap', value: 'Head and Neck' },
  { id: 'Submissions::Ccmap', value: 'Children' },
  { id: 'Submissions::Hbcmap', value: 'HBCR' }
];

export enum CMAPType {
  BCMAP = 'Submissions::Bcmap',
  CRCMAP = 'Submissions::Crcmap',
  LCMAP = 'Submissions::Lcmap',
  LUCMAP = 'Submissions::Lucmap',
  GCMAP = 'Submissions::Gcmap',
  PCMAP = 'Submissions::Pcmap',
  EGCMAP = 'Submissions::Egcmap',
  HNCMAP = 'Submissions::Hncmap',
  CCMAP = 'Submissions::Ccmap',
  HBCMAP = 'Submissions::Hbcmap'
}

export const OME_DISEASE_STATUS_OPTION: KeyValue[] = [
  { id: '', value: '[BLANK]' },
  { id: 'no_evidence_of_disease', value: 'No evidence of disease' },
  { id: 'stable_disease', value: 'Stable Disease' },
  { id: 'progressive_disease', value: 'Progressive Disease' },
  { id: 'recurrence_of_disease', value: i18n.t('recurrence_of_disease', { ns: 'patient_profile' }) },
  { id: 'died_from_disease', value: 'Died from disease' },
  { id: 'died_from_unrelated_cause', value: 'Died from unrelated cause' },
  { id: 'disease_status_unknown_unevaluable', value: 'Disease status unknown / Unevaluable' }
];

export const OME_THERAPEUTIC_RESPONSE_OPTION: KeyValue[] = [
  { id: '', value: '[BLANK]' },
  { id: 'SR', value: 'Stable Response' },
  { id: 'PR', value: 'Partial Response' },
  { id: 'CR', value: 'Complete Response' },
  { id: 'NED', value: 'No Evidence of Disease' },
  { id: 'PD', value: 'Progressive Disease' }
];

export const OME_RECURRENCE_OF_DISEASE_OPTION: KeyValue[] = [
  { id: '', value: '[BLANK]' },
  { id: 'general', value: 'General' },
  { id: 'regional', value: 'Regional' },
  { id: 'local', value: 'Local' },
  { id: 'distant', value: i18n.t('distant', { ns: 'patient_profile' }) },
  { id: 'progressive', value: 'Progressive' }
];

export const OME_TREATMENT_OPTION: KeyValue[] = [
  { id: '', value: '[BLANK]' },
  { id: 'undergoing_treatment', value: i18n.t('undergoing_treatment', { ns: 'patient_profile' }) },
  { id: 'completed_treatment', value: 'Completed Treatment' },
  { id: 'incomplete_treatment', value: 'Incomplete treatment' },
  { id: 'no_treatment_best_supportive_care', value: 'No treatment/best supportive care' },
  { id: 'treatment_unknown', value: 'Treatment unknown' },
  { id: 'lost_to_followup', value: 'Lost to follow up' }
];

export const FOLLOW_UP_STATUS: KeyValue[] = [
  { id: '', value: '[BLANK]' },
  { id: 'lost_to_follow_up', value: 'Lost to Follow-up' },
  { id: 'abandonment_of_treatment', value: 'Abandonment of Treatment' }
];

const BCMAP_DRUG_REGIMEN = [
  { id: 'TREATMENT_ARM_1', value: 'TREATMENT ARM, Tx1 - AC' },
  { id: 'TREATMENT_ARM_2', value: 'TREATMENT ARM, Tx2 - TC' },
  { id: 'TREATMENT_ARM_3', value: 'TREATMENT ARM, Tx3 - AC-T' },
  { id: 'TREATMENT_ARM_4', value: 'TREATMENT ARM, Tx4 - TCb' },
  { id: 'TREATMENT_ARM_5', value: 'TREATMENT ARM, Tx5 - AC-TH' },
  { id: 'TREATMENT_ARM_6', value: 'TREATMENT ARM, Tx6 - TC-H' },
  { id: 'TREATMENT_ARM_7', value: 'TREATMENT ARM, Tx7 - CMF' },
  { id: 'TREATMENT_ARM_8', value: 'TREATMENT ARM, Tx8 - Tamoxifen' },
  { id: 'TREATMENT_ARM_9', value: 'TREATMENT ARM, Tx9 - Letrozole' },
  { id: 'Other', value: 'Other' }
  // { id: 'TREATMENT_ARM_10', value: 'TREATMENT ARM 10 TCbH' }
];

const HBCMAP_DRUG_REGIMEN = [
  { id: 'TREATMENT_ARM_1', value: 'TREATMENT ARM, Tx1 - Gos-Bica' },
  { id: 'Other', value: 'Other' }
];

const CRCMAP_DRUG_REGIMEN = [
  { id: 'TREATMENT_ARM_CC_TX1', value: 'TREATMENT ARM, COLON CARCINOMA Tx1 - Capecitabine' },
  { id: 'TREATMENT_ARM_CC_TX2', value: 'TREATMENT ARM, COLON CARCINOMA Tx2 - Capecitabine' },
  { id: 'TREATMENT_ARM_CC_TX3', value: 'TREATMENT ARM, COLON CARCINOMA Tx3 - CapeOx' },
  { id: 'TREATMENT_ARM_CC_TX4', value: 'TREATMENT ARM, COLON CARCINOMA Tx4 - FOLFIRI' },
  { id: 'TREATMENT_ARM_RC_TX1', value: 'TREATMENT ARM, RECTUM CARCINOMA Tx1 - Cape-CapeRT' },
  { id: 'TREATMENT_ARM_RC_TX2', value: 'TREATMENT ARM, RECTUM CARCINOMA Tx2 - CapeRT-Capeox' },
  { id: 'TREATMENT_ARM_RC_TX3', value: 'TREATMENT ARM, RECTUM CARCINOMA Tx3 - FOLFIRI' },
  { id: 'Other', value: 'Other' }
];

const LUCMAP_DRUG_REGIMEN = [
  { id: 'TREATMENT_ARM_1', value: 'TREATMENT ARM, Tx1 - Cis-Pem' },
  { id: 'TREATMENT_ARM_2', value: 'TREATMENT ARM, Tx2 - Carbo-Pem' },
  { id: 'TREATMENT_ARM_3', value: 'TREATMENT ARM, Tx3 - Carbo-Gem' },
  { id: 'TREATMENT_ARM_4', value: 'TREATMENT ARM, Tx4 - Carbo-Doce' },
  { id: 'TREATMENT_ARM_5', value: 'TREATMENT ARM, Tx5 - Cis-Gem' },
  { id: 'TREATMENT_ARM_6', value: 'TREATMENT ARM, Tx6 - Cis-Etop' },
  { id: 'TREATMENT_ARM_7', value: 'TREATMENT ARM, Tx7 - Carbo-Etop' },
  { id: 'TREATMENT_ARM_8', value: 'TREATMENT ARM, Tx8 - Carbo-Pacli (with RT)' },
  { id: 'TREATMENT_ARM_9', value: 'TREATMENT ARM, Tx9 - Pacli (with RT)' },
  { id: 'TREATMENT_ARM_10', value: 'TREATMENT ARM, Tx10 - Cis-Etop (with RT)' },
  { id: 'Other', value: 'Other' }
];

const LCMAP_DRUG_REGIMEN = [
  { id: 'TREATMENT_ARM_1', value: 'TREATMENT ARM, Tx1 - CHOP' },
  { id: 'TREATMENT_ARM_2', value: 'TREATMENT ARM, Tx2 - CHOP-R' },
  { id: 'TREATMENT_ARM_3', value: 'TREATMENT ARM, Tx3 - GEMOX/R' },
  { id: 'TREATMENT_ARM_4', value: 'TREATMENT ARM, Tx4 - DHAP/R' },
  { id: 'Other', value: 'Other' }
];

const GCMAP_DRUG_REGIMEN = [
  { id: 'TREATMENT_ARM_CERVIX_CARCINOMA_1', value: 'TREATMENT ARM, CERVIX CARCINOMA, Tx1 - CisRT' },
  { id: 'TREATMENT_ARM_CERVIX_CARCINOMA_2', value: 'TREATMENT ARM, CERVIX CARCINOMA, Tx2 - Carbo-Pacli' },
  { id: 'TREATMENT_ARM_CERVIX_CARCINOMA_3', value: 'TREATMENT ARM, CERVIX CARCINOMA, Tx3 - Cis-Pacli' },
  { id: 'TREATMENT_ARM_CERVIX_CARCINOMA_4', value: 'TREATMENT ARM, CERVIX CARCINOMA, Tx4 - Gem-Cis' },
  { id: 'TREATMENT_ARM_CERVIX_CARCINOMA_5', value: 'TREATMENT ARM, CERVIX CARCINOMA, Tx5 - Cis' },
  { id: 'TREATMENT_ARM_CERVIX_CARCINOMA_6', value: 'TREATMENT ARM, CERVIX CARCINOMA, Tx6 - Cis' },
  { id: 'TREATMENT_ARM_CERVIX_CARCINOMA_7', value: 'TREATMENT ARM, CERVIX CARCINOMA, Tx7 - Pacli' },
  { id: 'TREATMENT_ARM_CERVIX_CARCINOMA_8', value: 'TREATMENT ARM, CERVIX CARCINOMA, Tx8 - Gem' },
  { id: 'TREATMENT_ARM_CERVIX_CARCINOMA_9', value: 'TREATMENT ARM, CERVIX CARCINOMA, Tx9 - Doce' },
  { id: 'TREATMENT_ARM_UTERINE_CARCINOMA_1', value: 'TREATMENT ARM, UTERINE CARCINOMA, Tx1 - Carbo-Pacli' },
  { id: 'TREATMENT_ARM_UTERINE_CARCINOMA_2', value: 'TREATMENT ARM, UTERINE CARCINOMA, Tx2 - Cis-Doxo' },
  { id: 'TREATMENT_ARM_UTERINE_CARCINOMA_3', value: 'TREATMENT ARM, UTERINE CARCINOMA, Tx3 - Doxo-Gem' },
  { id: 'TREATMENT_ARM_UTERINE_CARCINOMA_4', value: 'TREATMENT ARM, UTERINE CARCINOMA, Tx4 - Doxo' },
  { id: 'TREATMENT_ARM_UTERINE_SARCOMA_1', value: 'TREATMENT ARM, UTERINE SARCOMA, Tx1 - Doxo' },
  { id: 'TREATMENT_ARM_UTERINE_SARCOMA_2', value: 'TREATMENT ARM, UTERINE SARCOMA, Tx2 - Gem' },
  { id: 'TREATMENT_ARM_OVARIAN_CARCINOMA_1', value: 'TREATMENT ARM, OVARIAN CARCINOMA, Tx1 - Carbo-Pacli' },
  { id: 'TREATMENT_ARM_OVARIAN_CARCINOMA_2', value: 'TREATMENT ARM, OVARIAN CARCINOMA, Tx2 - Gem-Cis' },
  { id: 'TREATMENT_ARM_OVARIAN_CARCINOMA_3', value: 'TREATMENT ARM, OVARIAN CARCINOMA, Tx3 - BEP' },
  { id: 'TREATMENT_ARM_VULVAR_CARCINOMA_1', value: 'TREATMENT ARM, VULVAR CARCINOMA w/wo RT, Tx1 - Cis' },
  { id: 'TREATMENT_ARM_VULVAR_CARCINOMA_2', value: 'TREATMENT ARM, VULVAR CARCINOMA w/wo RT, Tx2 - Cape' },
  { id: 'TREATMENT_ARM_VULVAR_CARCINOMA_3', value: 'TREATMENT ARM, VULVAR CARCINOMA w/wo RT, Tx3 - FU' },
  {
    id: 'TREATMENT_ARM_GESTATIONAL_TROPHOBLASTIC_DISEASE_1',
    value: 'TREATMENT ARM, GESTATIONAL TROPHOBLASTIC DISEASE, Tx1 - EMACO'
  },
  {
    id: 'TREATMENT_ARM_GESTATIONAL_TROPHOBLASTIC_DISEASE_2',
    value: 'TREATMENT ARM, GESTATIONAL TROPHOBLASTIC DISEASE, Tx2 - MTX'
  },
  { id: 'Other', value: 'Other' }
];

const PCMAP_DRUG_REGIMEN = [
  { id: 'TREATMENT_ARM_1', value: 'TREATMENT ARM, Tx1 - Gos-Bica' },
  { id: 'TREATMENT_ARM_2', value: 'TREATMENT ARM, Tx2 - Leu-Fluta' },
  { id: 'TREATMENT_ARM_3', value: 'TREATMENT ARM, Tx3 - Bica' },
  { id: 'TREATMENT_ARM_4', value: 'TREATMENT ARM, Tx4 - Fluta' },
  { id: 'TREATMENT_ARM_5', value: 'TREATMENT ARM, Tx5 - Doc-Pred' },
  { id: 'Other', value: 'Other' }
];

const EGCMAP_DRUG_REGIMEN = [
  {
    id: 'TREATMENT_ARM_1',
    value: 'TREATMENT ARM, 1st LINE: WITH ADV/METS (Pre-Op ChemoRT or ChemoRT w/out Surgery), Tx1 - Pre-Op Pacli-Oxa-RT'
  },
  {
    id: 'TREATMENT_ARM_2',
    value: 'TREATMENT ARM, 1st LINE: WITH ADV/METS (Pre-Op ChemoRT or ChemoRT w/out Surgery), Tx2 - CapeOx-RT'
  },
  {
    id: 'TREATMENT_ARM_3',
    value: 'TREATMENT ARM, 1st LINE: WITH ADV/METS (Peri-Op - 4 cycles preop and 4 cycles postop), Tx3 - FLCD'
  },
  {
    id: 'TREATMENT_ARM_4',
    value: 'TREATMENT ARM, 1st LINE: WITH ADV/METS (Peri-Op - 4 cycles preop and 4 cycles postop), Tx4 - XELOX'
  },
  { id: 'TREATMENT_ARM_5', value: 'TREATMENT ARM, 2nd LINE: ADVANCED/ METASTATIC, Tx5 - TRASTUZ+' },
  { id: 'Other', value: 'Other' }
];

const HNCMAP_DRUG_REGIMEN = [
  { id: 'TREATMENT_ARM_1', value: 'TREATMENT ARM, Tx1 - Cis-RT then Cis-FU' },
  { id: 'TREATMENT_ARM_2', value: 'TREATMENT ARM, Tx2 - CisRT' },
  { id: 'TREATMENT_ARM_3', value: 'TREATMENT ARM, Tx3 - CisRT' },
  { id: 'TREATMENT_ARM_4', value: 'TREATMENT ARM, Tx4 - Induction Doce-Cis then Cis-RT' },
  { id: 'TREATMENT_ARM_5', value: 'TREATMENT ARM, Tx5 - Cis/ Carbo-Gem' },
  { id: 'TREATMENT_ARM_6', value: 'TREATMENT ARM, Tx6 - Cis/ Carbo-Pacli' },
  { id: 'TREATMENT_ARM_7', value: 'TREATMENT ARM, Tx7 - Cape' },
  { id: 'TREATMENT_ARM_8', value: 'TREATMENT ARM, Tx8 - Gem' },
  { id: 'TREATMENT_ARM_9', value: 'TREATMENT ARM, Tx9 - Doce' },
  { id: 'TREATMENT_ARM_10', value: 'TREATMENT ARM, Tx10 - MTX' },
  { id: 'Other', value: 'Other' }
];

const CCMAP_DRUG_REGIMEN = [
  { id: 'TREATMENT_ARM_ALL_1', value: 'TREATMENT ARM, ACUTE LYMPHOCYTIC LEUKEMIA, Tx1 - ALL' },
  { id: 'TREATMENT_ARM_AML_1', value: 'TREATMENT ARM, ACUTE MYELOGENOUS LEUKEMIA, Tx1' },
  { id: 'TREATMENT_ARM_CML_1', value: 'TREATMENT ARM, CHRONIC MYELOGENUOS LEUKEMIA, Tx1 - Imatinib' },
  {
    id: 'TREATMENT_ARM_CLL_1',
    value:
      'TREATMENT ARM, CHRONIC LYMPHOCYTIC LEUKEMIA/ SMALL LYMPHOCYTIC LYMPHOMA without del(17p)/TP53 Mutation; Tx1 - FCR'
  },
  {
    id: 'TREATMENT_ARM_CLL_SLL_1',
    value:
      'TREATMENT ARM, CHRONIC LYMPHOCYTIC LEUKEMIA/ SMALL LYMPHOCYTIC LYMPHOMA with del(17p)/TP53 Mutation; Tx1 - RM'
  },
  { id: 'TREATMENT_ARM_NHL_AMBL_1', value: 'TREATMENT ARM, NON-HODGKIN LYMPHOMA, Tx1 - POG9219 Regimen' },
  { id: 'TREATMENT_ARM_NHL_AMBL_2', value: 'TREATMENT ARM, NON-HODGKIN LYMPHOMA, Tx2 - COG ANHL 1131 Regimen B' },
  { id: 'TREATMENT_ARM_NHL_AMBL_3', value: 'TREATMENT ARM, NON-HODGKIN LYMPHOMA, Tx3 - COG 1131 Regimen C1' },
  { id: 'TREATMENT_ARM_HL_1', value: 'TREATMENT ARM, HODGKIN LYMPHOMA, Tx1 - COG ABVE-PC' },
  { id: 'TREATMENT_ARM_BT_1', value: 'TREATMENT ARM, BRAIN TUMOR, Tx1 - TMZ' },
  { id: 'TREATMENT_ARM_R_1', value: 'TREATMENT ARM, RETINOBLASTOMA, Tx1 - CEV' },
  { id: 'TREATMENT_ARM_R_2', value: 'TREATMENT ARM, RETINOBLASTOMA, Tx2 - CAV' },
  { id: 'TREATMENT_ARM_WT_1', value: 'TREATMENT ARM, WILMS’ TUMOR, Tx1 - NWTS/ SIOP ' },
  { id: 'TREATMENT_ARM_STS_1', value: 'TREATMENT ARM, SOFT TISSUE SARCOMA, Tx1 - IFOS-DOXO' },
  { id: 'TREATMENT_ARM_OS_1', value: 'TREATMENT ARM, OSTEOSARCOMA 1 st Line, Tx1 - Pre-Op (MAP)' },
  { id: 'TREATMENT_ARM_OS_2', value: 'TREATMENT ARM, OSTEOSARCOMA 1 st Line, Tx2 - Post-Op (MAP)' },
  { id: 'TREATMENT_ARM_OS_3', value: 'TREATMENT ARM, OSTEOSARCOMA 2nd Line, Tx3 - Osteo 2L' },
  { id: 'TREATMENT_ARM_ES_4', value: 'TREATMENT ARM, EWINGS SARCOMA 1 st Line, Tx4 - ES-IDE' },
  { id: 'TREATMENT_ARM_ES_5', value: 'TREATMENT ARM, EWINGS SARCOMA 2 nd Line, Tx5 - ES' },
  { id: 'SUPPORT_DRUGS', value: 'SUPPORT DRUGS' },
  { id: 'Other', value: 'Other' }
];

export const getDrugRegimen = (type: string | null) => {
  switch (type) {
    case CMAPType.CRCMAP:
      return CRCMAP_DRUG_REGIMEN;
    case CMAPType.CCMAP:
      return CCMAP_DRUG_REGIMEN;
    case CMAPType.LUCMAP:
      return LUCMAP_DRUG_REGIMEN;
    case CMAPType.LCMAP:
      return LCMAP_DRUG_REGIMEN;
    case CMAPType.GCMAP:
      return GCMAP_DRUG_REGIMEN;
    case CMAPType.PCMAP:
      return PCMAP_DRUG_REGIMEN;
    case CMAPType.EGCMAP:
      return EGCMAP_DRUG_REGIMEN;
    case CMAPType.HNCMAP:
      return HNCMAP_DRUG_REGIMEN;
    case CMAPType.HBCMAP:
      return HBCMAP_DRUG_REGIMEN;
    default:
      return BCMAP_DRUG_REGIMEN;
  }
};

export const CMAP_VERBAL_OPTIONS: KeyValue[] = [
  { id: '0', value: 'Positive' },
  { id: '1', value: 'Other Complaint, Whimper' },
  { id: '2', value: 'Pain, Crying' }
];

export const CMAP_BODY_MOVEMENT_OPTIONS: KeyValue[] = [
  { id: '0', value: 'Moves Easily' },
  { id: '1', value: 'Neutral Shifting' },
  { id: '2', value: 'Tense, Flailing arms/legs' }
];

export const CMAP_FACIAL_OPTIONS: KeyValue[] = [
  { id: '0', value: 'Smiling' },
  { id: '1', value: 'Neutral Shifting' },
  { id: '2', value: 'Frown, Grimace' },
  { id: '3', value: 'Clenched Teeth' }
];

export const CMAP_TOUCHING_OPTIONS: KeyValue[] = [
  { id: '0', value: 'No Touching' },
  { id: '1', value: 'Reaching, Patting' },
  { id: '2', value: 'Grabbing' }
];

export const CMAP_LONGEST_JOB_HELD: KeyValue[] = [
  { id: '1', value: 'Agriculture/Farming/Fishing' },
  { id: '2', value: 'Textile Industry/Manufacturing' },
  { id: '3', value: 'Manufacturing/Industrial other than Textile' },
  { id: '4', value: 'Administrative/Office' },
  { id: '5', value: 'Services & Sales' },
  { id: '6', value: 'Health/Medical Care' },
  { id: '7', value: 'Driver/Similar Skilled' },
  { id: '8', value: 'Housework, Hired' },
  { id: '9', value: 'House Management, Owns House' },
  { id: '10', value: 'Labourer/Unskilled' },
  { id: '11', value: 'Bar/Massage parlour/Discotheque' },
  { id: '12', value: 'Health Spa/Beauty Parlour' },
  { id: '14', value: 'Never had a Job' },
  { id: '15', value: 'Other' }
];

export const FAMILY_RELATIVES_GENETIC_DISPOSITION = [
  'Unknown',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'More than 10'
];

export const LAST_TIME_BREAST_CANCER_SCREENING = [
  'Never',
  'In the last 3 month',
  'In the last 6 month',
  '1 year ago',
  '2 years ago',
  'More than 2 years ago'
];

export const LAST_TIME_OVARIAN_CANCER_SCREENING = [
  'Never',
  'In the last 3 month',
  'In the last 6 month',
  '1 year ago',
  '2 years ago',
  'More than 2 years ago'
];

export const SERVINGS_PER_DAY = ['1 serving', '2-3 servings', 'Once a week or less'];

export const HAS_USED_ORAL_CONTRACEPTIVES = ['Never', 'Former', 'Currently using'];

export const MENOPAUSAL_STATUS = ['Pre-menopausal', 'Post-menopausal'];

export const NATIONALITIES = [
  'Afghan',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Anguillan',
  'Argentine',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bermudian',
  'Bhutanese',
  'Bolivian',
  'Botswanan',
  'Brazilian',
  'British',
  'British Virgin Islander',
  'Bruneian',
  'Bulgarian',
  'Burkinan',
  'Burmese',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Cayman Islander',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Citizen of Antigua and Barbuda',
  'Citizen of Bosnia and Herzegovina',
  'Citizen of Guinea-Bissau',
  'Citizen of Kiribati',
  'Citizen of Seychelles',
  'Citizen of the Dominican Republic',
  'Citizen of Vanuatu',
  'Colombian',
  'Comoran',
  'Congolese (Congo)',
  'Congolese (DRC)',
  'Cook Islander',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cymraes',
  'Cymro',
  'Cypriot',
  'Czech',
  'Danish',
  'Djiboutian',
  'Dominican',
  'Dutch',
  'East Timorese',
  'Ecuadorean',
  'Egyptian',
  'Emirati',
  'English',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Faroese',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Gibraltarian',
  'Greek',
  'Greenlandic',
  'Grenadian',
  'Guamanian',
  'Guatemalan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Honduran',
  'Hong Konger',
  'Hungarian',
  'Icelandic',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakh',
  'Kenyan',
  'Kittitian',
  'Kosovan',
  'Kuwaiti',
  'Kyrgyz',
  'Lao',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtenstein citizen',
  'Lithuanian',
  'Luxembourger',
  'Macanese',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivian',
  'Malian',
  'Maltese',
  'Marshallese',
  'Martiniquais',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monegasque',
  'Mongolian',
  'Montenegrin',
  'Montserratian',
  'Moroccan',
  'Mosotho',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'New Zealander',
  'Nicaraguan',
  'Nigerian',
  'Nigerien',
  'Niuean',
  'North Korean',
  'Northern Irish',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Palestinian',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Pitcairn Islander',
  'Polish',
  'Portuguese',
  'Prydeinig',
  'Puerto Rican',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Salvadorean',
  'Sammarinese',
  'Samoan',
  'Sao Tomean',
  'Saudi Arabian',
  'Scottish',
  'Senegalese',
  'Serbian',
  'Sierra Leonean',
  'Singaporean',
  'Slovak',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Korean',
  'South Sudanese',
  'Spanish',
  'Sri Lankan',
  'St Helenian',
  'St Lucian',
  'Stateless',
  'Sudanese',
  'Surinamese',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Taiwanese',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian',
  'Tristanian',
  'Tunisian',
  'Turkish',
  'Turkmen',
  'Turks and Caicos Islander',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'Uruguayan',
  'Uzbek',
  'Vatican citizen',
  'Venezuelan',
  'Vietnamese',
  'Vincentian',
  'Wallisian',
  'Welsh',
  'Yemeni',
  'Zambian',
  'Zimbabwean'
];
