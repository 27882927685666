import { Pagination } from 'models/store';

function createEndpoint(name: string, url: string) {
  return {
    name,
    url
  };
}
interface RequestFilterParams {
  id?: number | string;
  patient_health_record_id?: number;
  patient_id?: number;
  clinic_id?: number;
  type?: string;
  patient_limit?: number;
  active?: boolean;
  owner_id?: number;
  owner_type?: string;
  data_type?: boolean;
  followup_id?: number;
  submission_form_id?: number;
  status?: string | string[];
  from_date?: string;
  to_date?: string;
  administered?: boolean;
  account_id?: number;
  term?: string;
  doctor_id?: number;
  file_type?: string;
  appointment_id?: number;
  user_id?: number;
  procedure_id?: number;
  procedure_name?: string;
  is_service?: boolean;
  ref_service_id?: number;
  global?: boolean;
}

function parseParams(options: any) {
  if (!options) {
    return '';
  }
  const tmp = Object.entries(options)
    .filter(opt => opt[1] !== undefined && opt[1] !== null)
    .map(opt => `${opt[0]}=${opt[1]}`)
    .reduce((acc, cur) => `${acc}&${cur}`, '');
  return `?${tmp.startsWith('&') ? tmp.substr(1) : tmp}`;
}

export const ENDPOINTS = {
  BILLING_FEES: createEndpoint('billingFees', '/api/doctor/v3/billing/fee'),
  SUBSCRIPTION: createEndpoint('billingFees', '/api/doctor/v3/subscription'),
  SEND_ATTACHMENT: createEndpoint('sendAttachmentToEmail', '/api/doctor/v3/phr/attachment/send_email'),
  PATIENT: createEndpoint('patient', '/api/outpatient/v1/patient'),
  MEDICAL_PROGRAM: {
    PER_PATIENT: createEndpoint('medicalProgramsPerPatient', '/api/doctor/v3/medical_program/by_patient'),
    CLINICAL_TRIAL_PARTICIPANTS: createEndpoint(
      'clinicalTrialParticipants',
      '/api/doctor/v3/medical_program/clinical_trial/participant'
    ),
    CLINICAL_TRIAL_PARTICIPANT_ATTACH_FILE: createEndpoint(
      'clinicalTrialParticipant',
      '/api/doctor/v3/medical_program/clinical_trial/participant/attach_file'
    ),
    PATIENT_SUPPORT_PARTICIPANTS: createEndpoint(
      'clinicalTrialParticipants',
      '/api/doctor/v3/medical_program/patient_support/participant'
    ),
    PATIENT_SUPPORT_PARTICIPANT_ATTACH_FILE: createEndpoint(
      'patientSupportParticipant',
      '/api/doctor/v3/medical_program/patient_support/participant/attach_file'
    )
  },
  SESSION: {
    CREATE_PATIENT: createEndpoint('registerPatient', '/api/doctor/v1/session/create_patient'),
    REGISTER_DOCTOR: createEndpoint('registerDoctor', '/api/doctor/v1/session/register_doctor'),
    FORGOT_PASSWORD: createEndpoint('forgotPassword', '/api/doctor/v1/session/forgot_password'),
    RESET_PASSWORD: createEndpoint('resetPassword', '/api/doctor/v1/session/reset_password'),
    ASSUME_MEMBER: createEndpoint('assumeMember', '/api/doctor/v1/session/assume_member'),
    CONFIRM_EMAIL: createEndpoint('confirmEmail', '/api/doctor/v1/session/confirm_email'),
    CREATE_REPRESENTATIVE: createEndpoint(
      'addMedicalRepresentative',
      '/api/doctor/v3/session/create_medical_representative'
    ),
    RESEND_OTP: createEndpoint('resendOTP', '/api/doctor/v3/user/resend_2fa_token'),
    CONFIRM_OTP: createEndpoint('resendOTP', '/api/doctor/v3/user/confirm_2fa_token')
  },
  USER: {
    DEFAULT: {
      GET: createEndpoint('getUser', '/api/doctor/v1/user'),
      PUT: createEndpoint('updateUser', '/api/doctor/v1/user')
    },
    AVATAR: {
      PUT: createEndpoint('updateUserAvatar', '/api/doctor/v1/user/avatar')
    },
    CHANGE_PASSWORD: {
      PUT: createEndpoint('changePassword', '/api/doctor/v1/user/change_password')
    }
  },
  REFERENCE: {
    SEARCH_DIAGNOSTIC: createEndpoint('searchProcedure', '/api/doctor/v1/references/diagnostic'),
    SEARCH_PROCEDURE: createEndpoint('searchProcedureRefined', '/api/doctor/v3/references/procedure'),
    SPECIALIZATION: createEndpoint('fetchSpecializations', '/api/doctor/v1/references/specialization'),
    SPECIALIZATION_SEARCH: createEndpoint(
      'searchSpecialization',
      '/api/doctor/v1/references/specialization/mapping_search'
    ),
    SEARCH: {
      CLINIC: createEndpoint('searchClinic', '/api/doctor/v1/references/clinic'),
      HMO: createEndpoint('searchHMO', '/api/doctor/v1/references/hmo'),
      DIAGNOSIS: createEndpoint('searchDiagnosis', '/icd10/_search'),
      CASE_RATE_CODE: createEndpoint('searchCaseRateCode', '/rsv/_search'),
      PRESCRIPTION_GENERIC: createEndpoint('searchGeneric', '/api/doctor/v1/references/prescription/generic'),
      PRESCRIPTION_BRAND: createEndpoint('searchBrand', '/api/doctor/v1/references/prescription/brand'),
      MEDICATION: createEndpoint('searchMedication', '/medication/_search'),
      MEDICATION_CONTROLLED: createEndpoint('searchControlledMedication', '/medication_controlled/_search')
    },
    REQUEST: createEndpoint('diagnosis', '/api/doctor/v1/references/request'),
    PRESCRIPTION: createEndpoint('prescription', '/api/doctor/v1/references/request')
  },
  DOCTOR_SIGNATURE: {
    key: createEndpoint('signature', '/api/doctor/v2/doctor/signature'),
    parse: (id: number) => createEndpoint('signature', `/api/doctor/v2/doctor/signature/${id}`)
  },
  DOCTOR: {
    PROFILE: {
      GET: createEndpoint('getDoctorProfile', '/api/doctor/v1/doctor/profile')
    },
    CLINIC: {
      GET: {
        LIST: createEndpoint('getDoctorClinic', '/api/doctor/v3/clinic'),
        HEADER: createEndpoint('getDoctorClinicHeader', '/api/doctor/v1/doctor/clinic/header'),
        parse: (options: RequestFilterParams) =>
          createEndpoint('getAccountClinic', `/api/doctor/v3/clinic${parseParams(options)}`)
      },
      POST: createEndpoint('addDoctorClinic', '/api/doctor/v3/clinic'),
      V2: {
        HEADER: {
          GET: createEndpoint('getDoctorClinicHeader(V2)', '/api/doctor/v2/doctor/clinic/header'),
          UPDATE: createEndpoint('updateDoctorClinicHeader(V2)', '/api/doctor/v2/doctor/clinic/header')
        }
      },
      CLINIC_SCHEDULE: {
        key: createEndpoint('clinicSchedule', '/api/doctor/v3/clinic/schedule'),
        parse: (options: RequestFilterParams) =>
          createEndpoint('clinicSchedule', `/api/doctor/v3/clinic/schedule${parseParams(options)}`)
      },
      CLINICS: createEndpoint('doctorClinic', '/api/doctor/v3/clinic'),
      BLOCKED_SCHEDULE: {
        key: createEndpoint('blockedSchedule', '/api/doctor/v3/clinic/schedule/blocked_day'),
        parse: (options: RequestFilterParams) =>
          createEndpoint('blockedSchedule', `/api/doctor/v3/clinic/schedule/blocked_day${parseParams(options)}`)
      },
      UPLOAD_FORMS: {
        key: createEndpoint('clinicUploadForm', '/api/doctor/v3/clinic/form'),
        parse: (options: RequestFilterParams) =>
          createEndpoint('clinicUploadForms', `/api/doctor/v3/clinic/form${parseParams(options)}`)
      }
    },
    IDENTIFICATION: {
      PUT: createEndpoint('updateDoctorIdentification', '/api/doctor/v1/doctor/identification'),
      DELETE: createEndpoint('deleteDoctorIdentification', '/api/doctor/v1/doctor/identification')
    },
    SPECIALIZATION: {
      POST: createEndpoint('addAccountSpecialization', '/api/doctor/v3/account/specialization'),
      DELETE: createEndpoint('deleteAccountSpecialization', '/api/doctor/v3/account/specialization'),
      GET: {
        parse: (options: RequestFilterParams) =>
          createEndpoint('getAccountSpecializations', `/api/doctor/v3/account/specialization${parseParams(options)}`)
      }
    },
    PRESCRIPTION: {
      TEMPLATE: {
        GROUP: {
          GET: createEndpoint('getPrescriptionTemplateGroups', '/api/doctor/v2/doctor/prescription/template'),
          POST: createEndpoint('createPrescriptionTemplateGroup', '/api/doctor/v2/doctor/prescription/template'),
          PUT: createEndpoint('updatePrescriptionTemplateGroup', '/api/doctor/v2/doctor/prescription/template'),
          DELETE: createEndpoint('deletePrescriptionTemplateGroup', '/api/doctor/v2/doctor/prescription/template'),
          ITEM: {
            POST: createEndpoint(
              'addPrescriptionTemplateGroupItem',
              '/api/doctor/v2/doctor/prescription/template/item'
            ),
            DELETE: createEndpoint(
              'removePrescriptionTemplateGroupItem',
              '/api/doctor/v2/doctor/prescription/template/item'
            )
          }
        }
      }
    },
    APPOINTMENT: {
      GET: createEndpoint('getDoctorAppointment', '/api/doctor/v3/appointment/filter'),
      POST: createEndpoint('createNewAppointment', '/api/doctor/v3/appointment'),
      PUT: createEndpoint('updateAppointment', '/api/doctor/v3/appointment'),
      FILE: {
        key: createEndpoint('appointmentFile', '/api/doctor/v3/appointment/appointment_file'),
        parse: (options: RequestFilterParams) =>
          createEndpoint('appointmentFile', `/api/doctor/v3/appointment/appointment_file${parseParams(options)}`)
      }
    },
    STAGED_APPOINTMENT: {
      GET: {
        parse: (options?: RequestFilterParams) =>
          createEndpoint('getStagedAppointments', `/api/doctor/v3/appointment/staging_area${parseParams(options)}`)
      },
      CONFIRM: createEndpoint('confirmStagedAppointments', '/api/doctor/v3/appointment/staging_area/confirm'),
      REJECT: createEndpoint('rejectStagedAppointment', '/api/doctor/v3/appointment/staging_area/reject'),
      RESCHEDULE: createEndpoint('rescheduleStagedAppointment', '/api/doctor/v3/appointment/staging_area/reschedule')
    },
    PATIENT: {
      GET: createEndpoint('getDoctorPatient', '/api/doctor/v1/doctor/patient'),
      WATCHLIST: {
        GET: createEndpoint('getWatchlist', '/api/doctor/v2/doctor/watchlist'),
        DELETE: createEndpoint('removeFromWatchlist', '/api/doctor/v2/doctor/watchlist')
      },
      PRESCRIPTIONS: {
        FILTER: {
          parse: (options?: RequestFilterParams) =>
            createEndpoint(
              'getDoctorPatientPrescriptions',
              `/api/doctor/v1/phr/doctor/prescription/filter${parseParams(options)}`
            )
        },
        FILTER_PHR: {
          parse: (options?: RequestFilterParams) =>
            createEndpoint(
              'getDoctorPatientPrescriptions',
              `/api/doctor/v1/phr/doctor/prescription${parseParams(options)}`
            )
        }
      },
      SUBMISSION: {
        key: createEndpoint('getPatientSubmission', '/api/doctor/v2/doctor/patient/submission'),
        parse: (options?: RequestFilterParams) =>
          createEndpoint('getPatientSubmission', `/api/doctor/v2/doctor/patient/submission/${parseParams(options)}`)
      },
      NAVIGATOR_SUBMISSION: {
        key: createEndpoint('getNavigatorPatientSubmission', '/api/doctor/v2/navigator/patient/submission'),
        parse: (options?: RequestFilterParams) =>
          createEndpoint(
            'getNavigatorPatientSubmission',
            `/api/doctor/v2/navigator/patient/submission/${parseParams(options)}`
          )
      },
      CHANGE_HISTORY: createEndpoint('getChangeHistory', '/api/doctor/v1/doctor/patient/history'),
      UPDATE: createEndpoint('updateDoctorPatient', '/api/doctor/v1/doctor/patient'),
      LIST: {
        GET: createEndpoint('getDoctorPatientList', '/api/doctor/v1/doctor/patient/list'),
        FROM_NAVIGATOR: createEndpoint('getNavigatorPatientList', '/api/doctor/v1/navigator/account/patient'),
        GET_COUNT: createEndpoint('getDoctorPatientListCount', '/api/doctor/v1/doctor/patient/list_count')
      },
      UPDATE_AVATAR: createEndpoint('updateDoctorPatientAvatar', '/api/doctor/v1/doctor/patient/avatar'),
      VACCINATION: {
        GET: createEndpoint('getPatientVaccination', '/api/doctor/v1/phr/doctor/vaccination'),
        POST: createEndpoint('createPatientVaccination', '/api/doctor/v1/phr/doctor/vaccination'),
        PUT: createEndpoint('updatePatientVaccination', '/api/doctor/v1/phr/doctor/vaccination'),
        DELETE: createEndpoint('deletePatientVaccination', '/api/doctor/v1/phr/doctor/vaccination')
      },
      SUMMARY: {
        GET: createEndpoint('getPatientSummary', '/api/doctor/v1/phr/doctor/summary'),
        POST: createEndpoint('createPatientSummary', '/api/doctor/v1/phr/doctor/summary'),
        PUT: createEndpoint('updatePatientSummary', '/api/doctor/v1/phr/doctor/summary')
      },
      DISEASE_TREATMENT_STATUS: {
        PUT: createEndpoint(
          'updatePatientTreatmentStatus',
          '/api/doctor/v1/phr/doctor/summary/disease_and_treatment_status'
        )
      },
      TREATMENT_RECORDS: {
        GET: {
          key: createEndpoint('getTreatmentRecords', '/api/doctor/v1/phr/doctor/treatment_summary'),
          parse: (options?: RequestFilterParams) =>
            createEndpoint('getTreatmentRecords', `/api/doctor/v1/phr/doctor/treatment_summary${parseParams(options)}`)
        },
        SHARED: {
          key: createEndpoint('getSharedTreatmentRecords', '/api/doctor/v3/share_group/borrower/phrs'),
          parse: (options?: RequestFilterParams) =>
            createEndpoint(
              'getSharedTreatmentRecords',
              `/api/doctor/v3/share_group/borrower/phrs${parseParams(options)}`
            )
        },
        SURGERY: {
          POST: createEndpoint('addTreatmentSummarySurgery', '/api/doctor/v1/phr/doctor/treatment_summary/surgery'),
          PUT: createEndpoint('updateTreatmentSummarySurgery', '/api/doctor/v1/phr/doctor/treatment_summary/surgery'),
          DELETE: createEndpoint('deleteTreatmentSummarySurgery', '/api/doctor/v1/phr/doctor/treatment_summary/surgery')
        },
        RADIATION: {
          POST: createEndpoint('addTreatmentSummaryRadiation', '/api/doctor/v1/phr/doctor/treatment_summary/radiation'),
          PUT: createEndpoint(
            'updateTreatmentSummaryRadiation',
            '/api/doctor/v1/phr/doctor/treatment_summary/radiation'
          ),
          DELETE: createEndpoint(
            'deleteTreatmentSummaryRadiation',
            '/api/doctor/v1/phr/doctor/treatment_summary/radiation'
          )
        },
        CHEMOTHERAPY: {
          POST: createEndpoint(
            'addTreatmentSummaryChemotherapy',
            '/api/doctor/v1/phr/doctor/treatment_summary/chemotherapy'
          ),
          PUT: createEndpoint(
            'updateTreatmentSummaryChemotherapy',
            '/api/doctor/v1/phr/doctor/treatment_summary/chemotherapy'
          ),
          DELETE: createEndpoint(
            'deleteTreatmentSummaryChemotherapy',
            '/api/doctor/v1/phr/doctor/treatment_summary/chemotherapy'
          )
        },
        HORMONAL_THERAPY: {
          POST: createEndpoint(
            'addTreatmentSummaryHormonalTherapy',
            '/api/doctor/v1/phr/doctor/treatment_summary/hormonal_therapy'
          ),
          PUT: createEndpoint(
            'updateTreatmentSummaryHormonalTherapy',
            '/api/doctor/v1/phr/doctor/treatment_summary/hormonal_therapy'
          ),
          DELETE: createEndpoint(
            'deleteTreatmentSummaryHormonalTherapy',
            '/api/doctor/v1/phr/doctor/treatment_summary/hormonal_therapy'
          )
        },
        TARGETED_THERAPY: {
          POST: createEndpoint(
            'addTreatmentSummaryTargetedTherapy',
            '/api/doctor/v1/phr/doctor/treatment_summary/targeted_therapy'
          ),
          PUT: createEndpoint(
            'updateTreatmentSummaryTargetedTherapy',
            '/api/doctor/v1/phr/doctor/treatment_summary/targeted_therapy'
          ),
          DELETE: createEndpoint(
            'deleteTreatmentSummaryTargetedTherapy',
            '/api/doctor/v1/phr/doctor/treatment_summary/targeted_therapy'
          )
        }
      },
      SHARE_RECORDS: {
        POST: createEndpoint('sharePatientRecords', '/api/doctor/v1/doctor/account/add_patient')
      },
      SHARE_RECORDS_SOLO: {
        POST: createEndpoint('sharePatientRecords', '/api/doctor/v3/share_group/owner')
      },
      BORROWERS: {
        GET: createEndpoint('getBorrowers', '/api/doctor/v3/share_group/borrower')
      }
    },
    RECORD: {
      GET: {
        key: createEndpoint('getPatientRecordsNew', '/api/doctor/v3/phr'),
        parse: (options: RequestFilterParams) =>
          createEndpoint('getPatientRecordsNew', `/api/doctor/v3/phr${parseParams(options)}`),
        DEFAULT: createEndpoint('getPatientRecords', '/api/doctor/v1/phr/doctor/record'),
        FILTER: {
          key: createEndpoint('getPatientRecords', '/api/doctor/v1/phr/doctor/record/filter'),
          parse: (options?: RequestFilterParams) =>
            createEndpoint('getPatientRecords', `/api/doctor/v1/phr/doctor/record/filter${parseParams(options)}`)
        },
        HISTORICAL: {
          ATTACHMENTS: createEndpoint(
            'getHistoricalHealthRecordAttachment',
            '/api/doctor/v1/phr/doctor/historical_file'
          )
        }
      },
      POST: createEndpoint('createNewRecord', '/api/doctor/v1/phr/doctor/record'),
      SUBJECTIVE: {
        POST: createEndpoint('updateRecordSubjective', '/api/doctor/v1/phr/doctor/subjective') // should be "doctor/record/subjective" for clarity
      },
      ATTACHMENT: {
        POST: createEndpoint('addRecordAttachment', '/api/doctor/v1/phr/doctor/attachment'),
        DELETE: createEndpoint('deleteRecordAttachment', '/api/doctor/v1/phr/doctor/attachment'),
        GET: {
          parse: (options: RequestFilterParams) =>
            createEndpoint('getRecordAttachments', `/api/doctor/v1/phr/doctor/attachment${parseParams(options)}`)
        }
      },
      MEDICAL_CERTIFICATE: {
        UPDATE: createEndpoint('updateRecordMedicalCertificate', '/api/doctor/v3/account/medical_certificate'),
        GET: {
          parse: (options: RequestFilterParams) =>
            createEndpoint(
              'getRecordMedicalCertificate',
              `/api/doctor/v3/account/medical_certificate${parseParams(options)}`
            )
        }
      }
    },
    MEDICAL_CERTIFICATE: {
      COUNT: createEndpoint('getMedicalCertificate', '/api/doctor/v3/doctor/medical_certificate_template/count'),
      FETCH: createEndpoint('getMedicalCertificate', '/api/doctor/v3/doctor/medical_certificate_template'),
      UPDATE: createEndpoint('saveMedicalCertificate', '/api/doctor/v3/doctor/medical_certificate_template'),
      DELETE: createEndpoint('deleteMedicalCertificate', '/api/doctor/v3/doctor/medical_certificate_template')
    },
    IMMUNIZATION_CERTIFICATE: {
      key: createEndpoint('immunizationCertificate', '/api/doctor/v3/account/medical_certificate'),
      parse: (options: RequestFilterParams) =>
        createEndpoint('immunizationCertificate', `/api/doctor/v3/account/medical_certificate${parseParams(options)}`)
    },
    MEDICAL_REPRESENTATIVE: {
      GET_BY_ID: createEndpoint('getMedicalRepresentatives', '/api/doctor/v1/doctor/medical_representative'),
      LIST: createEndpoint('getMedicalRepresentativeList', '/api/doctor/v1/doctor/medical_representative/list'),
      UPDATE_INFO: createEndpoint('updateMedicalRepresentative', '/api/doctor/v1/doctor/medical_representative'),
      PROFILE: createEndpoint('createProfile', '/api/doctor/v1/representative/doctor/profile')
    },
    HMO: {
      key: createEndpoint('doctorHMO', '/api/doctor/v1/doctor/hmo'),
      parse: (options: RequestFilterParams) =>
        createEndpoint('doctorHMO', `/api/doctor/v1/doctor/hmo${parseParams(options)}`)
    }
  },
  PHR: {
    key: createEndpoint('getPatientRecordsNew', '/api/outpatient/v1/phr'),
    ATTACHMENT: createEndpoint('saveDiagnosisPrescription', '/api/doctor/v3/phr/attachment'),
    VITALS: {
      key: createEndpoint('vitals', '/api/doctor/v3/phr/vitals'),
      parse: (options: RequestFilterParams) =>
        createEndpoint('vitals', `/api/doctor/v3/phr/vitals${parseParams(options)}`)
    },
    DOCTOR: {
      DIAGNOSIS_PRESCRIPTION: {
        POST: createEndpoint('saveDiagnosisPrescription', '/api/doctor/v1/phr/doctor/diagnosis_prescription')
      },
      INSTRUCTION: {
        POST: createEndpoint('saveInstruction', '/api/doctor/v1/phr/doctor/instruction'),
        DELETE: createEndpoint('deleteInstruction', '/api/doctor/v1/phr/doctor/instruction')
      },
      PROCEDURE: {
        GET: createEndpoint('getProcedure', '/api/doctor/v1/phr/doctor/procedure'),
        POST: createEndpoint('saveProcedure', '/api/doctor/v1/phr/doctor/procedure'),
        PUT: createEndpoint('saveProcedure', '/api/doctor/v1/phr/doctor/procedure')
      },
      PRESCRIPTION: {
        POST: createEndpoint('insertPrescription', '/api/doctor/v1/phr/doctor/prescription'),
        PUT: createEndpoint('updatePrescription', '/api/doctor/v1/phr/doctor/prescription'),
        DELETE: {
          key: createEndpoint('removePrescription', '/api/doctor/v1/phr/doctor/prescription'),
          parse: (options: RequestFilterParams) =>
            createEndpoint('removePrescription', `/api/doctor/v1/phr/doctor/prescription${parseParams(options)}`)
        },
        GET: {
          key: createEndpoint('getPrescriptions', '/api/doctor/v1/phr/doctor/prescription'),
          parse: (options: RequestFilterParams) =>
            createEndpoint('getPrescriptions', `/api/doctor/v1/phr/doctor/prescription${parseParams(options)}`)
        }
      },
      DIAGNOSIS: {
        CANCER_TYPE: {
          LIVER: {
            POST: createEndpoint('insertLiverDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/liver_cancer'),
            PUT: createEndpoint('updateLiverDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/liver_cancer'),
            DELETE: {
              key: createEndpoint('removeLiverDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/liver_cancer'),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeLiverDiagnosis',
                  `/api/doctor/v1/phr/doctor/diagnosis/liver_cancer${parseParams(options)}`
                )
            }
          },
          LUNG: {
            POST: createEndpoint('insertLungDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/lung_cancer'),
            PUT: createEndpoint('updateLungDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/lung_cancer'),
            DELETE: {
              key: createEndpoint('removeLungDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/lung_cancer'),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeLungDiagnosis',
                  `/api/doctor/v1/phr/doctor/diagnosis/lung_cancer${parseParams(options)}`
                )
            }
          },
          COLON: {
            POST: createEndpoint('insertColonDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/colon_cancer'),
            PUT: createEndpoint('updateColonDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/colon_cancer'),
            DELETE: {
              key: createEndpoint('removeColonDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/colon_cancer'),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeColonDiagnosis',
                  `/api/doctor/v1/phr/doctor/diagnosis/colon_cancer${parseParams(options)}`
                )
            }
          },
          BREAST: {
            POST: createEndpoint('insertBreastDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/breast_cancer'),
            PUT: createEndpoint('updateBreastDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/breast_cancer'),
            DELETE: {
              key: createEndpoint('removeBreastDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/breast_cancer'),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeBreastDiagnosis',
                  `/api/doctor/v1/phr/doctor/diagnosis/breast_cancer${parseParams(options)}`
                )
            }
          }
        },
        RHEUMA: {
          key: createEndpoint('rheumaDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/rheumatoid_arthritis'),
          parse: (options: RequestFilterParams) =>
            createEndpoint(
              'rheumaDiagnosis',
              `/api/doctor/v1/phr/doctor/diagnosis/rheumatoid_arthritis${parseParams(options)}`
            )
        },
        PULMO_DIAGNOSIS: {
          BRONCHIAL_ASTHMA: {
            key: createEndpoint('bronchialAsthmaDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/bronchial_asthma'),
            parse: (options: RequestFilterParams) =>
              createEndpoint(
                'bronchialAsthmaDiagnosis',
                `/api/doctor/v1/phr/doctor/diagnosis/bronchial_asthma${parseParams(options)}`
              )
          },
          TUBERCULOSIS: {
            key: createEndpoint('tuberculosisDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis/tuberculosis'),
            parse: (options: RequestFilterParams) =>
              createEndpoint(
                'tuberculosisDiagnosis',
                `/api/doctor/v1/phr/doctor/diagnosis/tuberculosis${parseParams(options)}`
              )
          }
        },
        POST: createEndpoint('insertDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis'),
        PUT: createEndpoint('updateDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis'),
        DELETE: {
          key: createEndpoint('removeDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis'),
          parse: (options: RequestFilterParams) =>
            createEndpoint('removeDiagnosis', `/api/doctor/v1/phr/doctor/diagnosis${parseParams(options)}`)
        },
        GET: {
          key: createEndpoint('getDiagnosis', '/api/doctor/v1/phr/doctor/diagnosis'),
          parse: (options: RequestFilterParams) =>
            createEndpoint('getDiagnosis', `/api/doctor/v1/phr/doctor/diagnosis${parseParams(options)}`)
        },
        PER_PATIENT: {
          GET: createEndpoint('getDiagnosisPerPatient', '/api/doctor/v1/phr/doctor/by_patient/diagnosis')
        },
        TREATMENT_PLAN: {
          TREATMENT: {
            POST: createEndpoint('insertTreatment', '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatment'),
            PUT: createEndpoint('updateTreatment', '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatment'),
            DELETE: {
              key: createEndpoint('removeTreatment', '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatment'),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeTreatment',
                  `/api/doctor/v1/phr/doctor/oncology/diagnosis_treatment${parseParams(options)}`
                )
            }
          },
          SURGERY: {
            POST: createEndpoint(
              'insertTreatmentSurgery',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/surgery'
            ),
            PUT: createEndpoint(
              'updateTreatmentSurgery',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/surgery'
            ),
            DELETE: {
              key: createEndpoint(
                'removeTreatmentSurgery',
                '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/surgery'
              ),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeTreatmentSurgery',
                  `/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/surgery${parseParams(options)}`
                )
            }
          },
          RADIATION: {
            POST: createEndpoint(
              'insertTreatmentRadiation',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/radiation'
            ),
            PUT: createEndpoint(
              'updateTreatmentRadiation',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/radiation'
            ),
            DELETE: {
              key: createEndpoint(
                'removeTreatmentRadiation',
                '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/radiation'
              ),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeTreatmentRadiation',
                  `/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/radiation${parseParams(options)}`
                )
            }
          },
          RADIATION_BCI: {
            POST: createEndpoint(
              'insertTreatmentRadiationBci',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/radiation_bci'
            ),
            PUT: createEndpoint(
              'updateTreatmentRadiationBci',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/radiation_bci'
            ),
            DELETE: {
              key: createEndpoint(
                'removeTreatmentRadiationBci',
                '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/radiation_bci'
              ),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeTreatmentRadiationBci',
                  `/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/radiation_bci${parseParams(options)}`
                )
            }
          },
          THERAPY: {
            POST: createEndpoint(
              'insertTreatmentTherapy',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/therapy'
            ),
            PUT: createEndpoint(
              'updateTreatmentTherapy',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/therapy'
            ),
            DELETE: {
              key: createEndpoint(
                'removeTreatmentTherapy',
                '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/therapy'
              ),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeTreatmentTherapy',
                  `/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/therapy${parseParams(options)}`
                )
            }
          },
          AGENT: {
            POST: createEndpoint(
              'insertTreatmentAgent',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/agent'
            ),
            PUT: createEndpoint(
              'updateTreatmentAgent',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/agent'
            ),
            DELETE: {
              key: createEndpoint(
                'removeTreatmentAgent',
                '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/agent'
              ),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeTreatmentAgent',
                  `/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/agent${parseParams(options)}`
                )
            }
          },
          PROCEDURE: {
            POST: createEndpoint(
              'insertTreatmentProcedure',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/procedure'
            ),
            PUT: createEndpoint(
              'updateTreatmentProcedure',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/procedure'
            ),
            DELETE: {
              key: createEndpoint(
                'removeTreatmentProcedure',
                '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/procedure'
              ),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeTreatmentProcedure',
                  `/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/procedure${parseParams(options)}`
                )
            }
          },
          ANTI_CANCER: {
            POST: createEndpoint(
              'insertTreatmentAntiCancer',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/anti_cancer'
            ),
            PUT: createEndpoint(
              'updateTreatmentAntiCancer',
              '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/anti_cancer'
            ),
            DELETE: {
              key: createEndpoint(
                'removeTreatmentAntiCancer',
                '/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/anti_cancer'
              ),
              parse: (options: RequestFilterParams) =>
                createEndpoint(
                  'removeTreatmentAntiCancer',
                  `/api/doctor/v1/phr/doctor/oncology/diagnosis_treatments/anti_cancer${parseParams(options)}`
                )
            }
          }
        }
      },
      SUBJECTIVE: createEndpoint('saveSubjective', '/api/doctor/v1/phr/doctor/subjective'),
      VITALS: createEndpoint('saveVitals', '/api/doctor/v1/phr/doctor/vitals'),
      ROS: {
        POST: createEndpoint('saveReviewfSystem', '/api/doctor/v1/phr/doctor/ros'),
        GET: createEndpoint('getReviewOfSystem', '/api/doctor/v1/phr/doctor/ros')
      },
      PHYSICAL: {
        POST: createEndpoint('updatePhysicalExam', '/api/doctor/v1/phr/doctor/physical_exam'),
        GET: createEndpoint('getPhysicalExam', '/api/doctor/v1/phr/doctor/physical_exam')
      },
      PROCEDURES: {
        key: createEndpoint('getProcedure', '/api/doctor/v1/phr/doctor/procedure'),
        parse: (options: RequestFilterParams) =>
          createEndpoint('getProcedure', `/api/doctor/v1/phr/doctor/procedure${parseParams(options)}`)
      },
      RECORD: {
        key: createEndpoint('saveRecord', '/api/doctor/v1/phr/doctor/record'),
        parse: (options: RequestFilterParams) =>
          createEndpoint('getEncounters', `/api/doctor/v1/phr/doctor/record${parseParams(options)}`),
        FILTER: {
          key: createEndpoint('getPatientRecords', '/api/doctor/v1/phr/doctor/record/filter'),
          parse: (options?: RequestFilterParams) =>
            createEndpoint('getPatientRecords', `/api/doctor/v1/phr/doctor/record/filter${parseParams(options)}`)
        },
        TYPE_SPECIFIC: {
          PULMONOLOGY: {
            key: createEndpoint('savePulmoSpecificFields', '/api/doctor/v3/phr/type/pulmo'),
            parse: (options: RequestFilterParams) =>
              createEndpoint('getPulmoSpecificFields', `/api/doctor/v3/phr/type/pulmo${parseParams(options)}`)
          },
          ENDOCRINOLOGY: {
            key: createEndpoint('endoSpecificFields', '/api/doctor/v3/phr/type/endo'),
            parse: (options: RequestFilterParams) =>
              createEndpoint('getEndoSpecificFields', `/api/doctor/v3/phr/type/endo${parseParams(options)}`)
          },
          DIABETOLOGY: {
            key: createEndpoint('diabSpecificFields', '/api/doctor/v3/phr/type/diab'),
            parse: (options: RequestFilterParams) =>
              createEndpoint('getDiabSpecificFields', `/api/doctor/v3/phr/type/diab${parseParams(options)}`)
          }
        }
      },
      LABS: {
        parse: (options: RequestFilterParams) =>
          createEndpoint('parseGetAllLabs', `/api/doctor/v1/phr/doctor/test_group${parseParams(options)}`),
        ALL: createEndpoint('geAllLabsForPHR', '/api/doctor/v1/phr/doctor/test_group'),
        DELETE_RECORD: createEndpoint('deleteLabRecord', '/api/doctor/v1/phr/doctor/test_group'),
        GET_ALL_BY_PATIENT_ID: {
          key: createEndpoint('getAllLabsByPatientID', '/api/doctor/v1/phr/doctor/by_patient/test_group'),
          parse: (options: RequestFilterParams) =>
            createEndpoint(
              'getAllLabsByPatientID',
              `/api/doctor/v1/phr/doctor/by_patient/test_group${parseParams(options)}`
            )
        },
        ABG: {
          POST: createEndpoint('createAbg', '/api/doctor/v1/phr/doctor/test_result/abg'),
          PUT: createEndpoint('updateAbg', '/api/doctor/v1/phr/doctor/test_result/abg'),
          DELETE: createEndpoint('deleteAbg', '/api/doctor/v1/phr/doctor/test_result/abg')
        },
        BLEEDING_PARAMETER: {
          POST: createEndpoint('createBleedingParameter', '/api/doctor/v1/phr/doctor/test_result/bleeding_parameter'),
          PUT: createEndpoint('updateBleedingParameter', '/api/doctor/v1/phr/doctor/test_result/bleeding_parameter'),
          DELETE: createEndpoint('deleteBleedingParameter', '/api/doctor/v1/phr/doctor/test_result/bleeding_parameter')
        },
        CHEMISTRY: {
          POST: createEndpoint('createChemistry', '/api/doctor/v1/phr/doctor/test_result/chemistry'),
          PUT: createEndpoint('updateChemistry', '/api/doctor/v1/phr/doctor/test_result/chemistry'),
          DELETE: createEndpoint('deleteChemistry', '/api/doctor/v1/phr/doctor/test_result/chemistry')
        },
        CKD_PANEL: {
          POST: createEndpoint('createChemistry', '/api/doctor/v1/phr/doctor/test_result/ckd_panel'),
          PUT: createEndpoint('updateChemistry', '/api/doctor/v1/phr/doctor/test_result/ckd_panel'),
          DELETE: createEndpoint('deleteChemistry', '/api/doctor/v1/phr/doctor/test_result/ckd_panel')
        },
        CULTURE: {
          POST: createEndpoint('createCulture', '/api/doctor/v1/phr/doctor/test_result/culture'),
          PUT: createEndpoint('updateCulture', '/api/doctor/v1/phr/doctor/test_result/culture'),
          DELETE: createEndpoint('deleteCulture', '/api/doctor/v1/phr/doctor/test_result/culture')
        },
        FECALYSIS: {
          POST: createEndpoint('createFecalysis', '/api/doctor/v1/phr/doctor/test_result/fecalysis'),
          PUT: createEndpoint('updateFecalysis', '/api/doctor/v1/phr/doctor/test_result/fecalysis'),
          DELETE: createEndpoint('deleteFecalysis', '/api/doctor/v1/phr/doctor/test_result/fecalysis')
        },
        HEMATOLOGY: {
          POST: createEndpoint('createHematology', '/api/doctor/v1/phr/doctor/test_result/hematology'),
          PUT: createEndpoint('updateHematology', '/api/doctor/v1/phr/doctor/test_result/hematology'),
          DELETE: createEndpoint('deleteHematology', '/api/doctor/v1/phr/doctor/test_result/hematology')
        },
        SEROLOGY: {
          POST: createEndpoint('createSerology', '/api/doctor/v1/phr/doctor/test_result/serology'),
          PUT: createEndpoint('updateSerology', '/api/doctor/v1/phr/doctor/test_result/serology'),
          DELETE: createEndpoint('deleteSerology', '/api/doctor/v1/phr/doctor/test_result/serology')
        },
        URINE_CHEMISTRY: {
          POST: createEndpoint('createUrineChemistry', '/api/doctor/v1/phr/doctor/test_result/urine_chemistry'),
          PUT: createEndpoint('updateUrineChemistry', '/api/doctor/v1/phr/doctor/test_result/urine_chemistry'),
          DELETE: createEndpoint('deleteUrineChemistry', '/api/doctor/v1/phr/doctor/test_result/urine_chemistry')
        },
        ENDOCRINE: {
          POST: createEndpoint('createEndocrine', '/api/doctor/v1/phr/doctor/test_result/endocrine'),
          PUT: createEndpoint('updateEndocrine', '/api/doctor/v1/phr/doctor/test_result/endocrine'),
          DELETE: createEndpoint('deleteEndocrine', '/api/doctor/v1/phr/doctor/test_result/endocrine')
        },
        MICROBIOLOGY: {
          POST: createEndpoint('createMicrobiology', '/api/doctor/v1/phr/doctor/test_result/microbiology'),
          PUT: createEndpoint('updateMicrobiology', '/api/doctor/v1/phr/doctor/test_result/microbiology'),
          DELETE: createEndpoint('deleteMicrobiology', '/api/doctor/v1/phr/doctor/test_result/microbiology')
        },
        MICROSCOPY: {
          POST: createEndpoint('createMicroscopy', '/api/doctor/v1/phr/doctor/test_result/microscopy'),
          PUT: createEndpoint('updateMicroscopy', '/api/doctor/v1/phr/doctor/test_result/microscopy'),
          DELETE: createEndpoint('deleteMicroscopy', '/api/doctor/v1/phr/doctor/test_result/microscopy')
        },
        CANCER_MARKER: {
          POST: createEndpoint('createCancerMarker', '/api/doctor/v1/phr/doctor/test_result/cancer_marker'),
          PUT: createEndpoint('updateCancerMarker', '/api/doctor/v1/phr/doctor/test_result/cancer_marker'),
          DELETE: createEndpoint('deleteCancerMarker', '/api/doctor/v1/phr/doctor/test_result/cancer_marker')
        },
        CGM: {
          POST: createEndpoint('createCGM', '/api/doctor/v1/phr/doctor/test_result/cgm_panel'),
          PUT: createEndpoint('updateCGM', '/api/doctor/v1/phr/doctor/test_result/cgm_panel'),
          DELETE: createEndpoint('deleteCGM', '/api/doctor/v1/phr/doctor/test_result/cgm_panel')
        },
        HISTOPATHOLOGY: {
          POST: createEndpoint('createHistopathology', '/api/doctor/v1/phr/doctor/test_result/histopathology'),
          PUT: createEndpoint('updateHistopathology', '/api/doctor/v1/phr/doctor/test_result/histopathology'),
          DELETE: createEndpoint('deleteHistopathology', '/api/doctor/v1/phr/doctor/test_result/histopathology')
        },
        HORMONE_TEST: {
          POST: createEndpoint('createHormoneTest', '/api/doctor/v1/phr/doctor/test_result/hormone_test'),
          PUT: createEndpoint('updateHormoneTest', '/api/doctor/v1/phr/doctor/test_result/hormone_test'),
          DELETE: createEndpoint('deleteHormoneTest', '/api/doctor/v1/phr/doctor/test_result/hormone_test')
        },
        IMAGING: {
          GET: createEndpoint('getImaging', '/api/doctor/v1/phr/doctor/test_result/imaging'),
          POST: createEndpoint('createImaging', '/api/doctor/v1/phr/doctor/test_result/imaging'),
          PUT: createEndpoint('updateImaging', '/api/doctor/v1/phr/doctor/test_result/imaging'),
          DELETE: createEndpoint('deleteImaging', '/api/doctor/v1/phr/doctor/test_result/imaging'),
          BY_PATIENT: createEndpoint('getImagingByPatient', '/api/doctor/v1/phr/doctor/test_result/by_patient/imaging'),
          BREAST_ULTRASOUND: {
            GET: createEndpoint('getBreastUltrasound', '/api/doctor/v1/phr/doctor/test_result/breast_ultrasound'),
            POST: createEndpoint('postBreastUltrasound', '/api/doctor/v1/phr/doctor/test_result/breast_ultrasound'),
            PUT: createEndpoint('putBreastUltrasound', '/api/doctor/v1/phr/doctor/test_result/breast_ultrasound')
          },
          MMG: {
            GET: createEndpoint('getMMG', '/api/doctor/v1/phr/doctor/test_result/mammogram'),
            POST: createEndpoint('postMMG', '/api/doctor/v1/phr/doctor/test_result/mammogram'),
            PUT: createEndpoint('putMMG', '/api/doctor/v1/phr/doctor/test_result/mammogram')
          },
        },
        LIPID_PROFILE: {
          POST: createEndpoint('createLipidProfile', '/api/doctor/v1/phr/doctor/test_result/lipid_profiles'),
          PUT: createEndpoint('updateLipidProfile', '/api/doctor/v1/phr/doctor/test_result/lipid_profiles'),
          DELETE: createEndpoint('deleteLipidProfile', '/api/doctor/v1/phr/doctor/test_result/lipid_profiles')
        },
        THYROID: {
          POST: createEndpoint('createThyroid', '/api/doctor/v1/phr/doctor/test_result/thyroid'),
          PUT: createEndpoint('updateThyroid', '/api/doctor/v1/phr/doctor/test_result/thyroid'),
          DELETE: createEndpoint('deleteThyroid', '/api/doctor/v1/phr/doctor/test_result/thyroid')
        },
        TUMOR_MARKERS: {
          POST: createEndpoint('createTumorMarkers', '/api/doctor/v1/phr/doctor/test_result/tumor_marker_general'),
          PUT: createEndpoint('updateTumorMarkers', '/api/doctor/v1/phr/doctor/test_result/tumor_marker_general'),
          DELETE: createEndpoint('deleteTumorMarkers', '/api/doctor/v1/phr/doctor/test_result/tumor_marker_general')
        },
        URINALYSIS: {
          POST: createEndpoint('createUrinalysis', '/api/doctor/v1/phr/doctor/test_result/urinalysis'),
          PUT: createEndpoint('updateUrinalysis', '/api/doctor/v1/phr/doctor/test_result/urinalysis'),
          DELETE: createEndpoint('deleteUrinalysis', '/api/doctor/v1/phr/doctor/test_result/urinalysis')
        },
        LUPUS_PANEL: {
          POST: createEndpoint('createLupusPanel', '/api/doctor/v1/phr/doctor/test_result/lupus_panel'),
          PUT: createEndpoint('updateLupusPanel', '/api/doctor/v1/phr/doctor/test_result/lupus_panel'),
          DELETE: createEndpoint('deleteLupusPanel', '/api/doctor/v1/phr/doctor/test_result/lupus_panel')
        },
        HEPATITIS: {
          POST: createEndpoint('createHepatitis', '/api/doctor/v1/phr/doctor/test_result/hepatitis'),
          PUT: createEndpoint('updateHepatitis', '/api/doctor/v1/phr/doctor/test_result/hepatitis'),
          DELETE: createEndpoint('deleteHepatitis', '/api/doctor/v1/phr/doctor/test_result/hepatitis')
        },
        ARTHRITIS_PANEL: {
          POST: createEndpoint('createArthritisPanel', '/api/doctor/v1/phr/doctor/test_result/arthritis_panel'),
          PUT: createEndpoint('updateArthritisPanel', '/api/doctor/v1/phr/doctor/test_result/arthritis_panel'),
          DELETE: createEndpoint('deleteArthritisPanel', '/api/doctor/v1/phr/doctor/test_result/arthritis_panel')
        },
        AA_PNH_MDS: {
          POST: createEndpoint('createAaPnhMds', '/api/doctor/v1/phr/doctor/test_result/aa_pnh_mds'),
          PUT: createEndpoint('updateAaPnhMds', '/api/doctor/v1/phr/doctor/test_result/aa_pnh_mds'),
          DELETE: createEndpoint('deleteAaPnhMds', '/api/doctor/v1/phr/doctor/test_result/aa_pnh_mds')
        },
        COAGULATION_DISEASE: {
          POST: createEndpoint('createCoagulationDisease', '/api/doctor/v1/phr/doctor/test_result/coagulation'),
          PUT: createEndpoint('updateCoagulationDisease', '/api/doctor/v1/phr/doctor/test_result/coagulation'),
          DELETE: createEndpoint('deleteCoagulationDisease', '/api/doctor/v1/phr/doctor/test_result/coagulation')
        },
        LEUKEMIA: {
          POST: createEndpoint('createLeukemia', '/api/doctor/v1/phr/doctor/test_result/leukemia'),
          PUT: createEndpoint('updateLeukemia', '/api/doctor/v1/phr/doctor/test_result/leukemia'),
          DELETE: createEndpoint('deleteLeukemia', '/api/doctor/v1/phr/doctor/test_result/leukemia')
        },
        LYMPHOMA: {
          POST: createEndpoint('createLymphoma', '/api/doctor/v1/phr/doctor/test_result/lymphoma'),
          PUT: createEndpoint('updateLymphoma', '/api/doctor/v1/phr/doctor/test_result/lymphoma'),
          DELETE: createEndpoint('deleteLymphoma', '/api/doctor/v1/phr/doctor/test_result/lymphoma')
        },
        PLASMA_CELL_DISORDER: {
          POST: createEndpoint(
            'createPlasmaCellDisorder',
            '/api/doctor/v1/phr/doctor/test_result/plasma_cell_disorder'
          ),
          PUT: createEndpoint('updatePlasmaCellDisorder', '/api/doctor/v1/phr/doctor/test_result/plasma_cell_disorder'),
          DELETE: createEndpoint(
            'deletePlasmaCellDisorder',
            '/api/doctor/v1/phr/doctor/test_result/plasma_cell_disorder'
          )
        },
        PLATELET_DISORDER: {
          POST: createEndpoint('createPlateletDisorder', '/api/doctor/v1/phr/doctor/test_result/platelet_disorder'),
          PUT: createEndpoint('updatePlateletDisorder', '/api/doctor/v1/phr/doctor/test_result/platelet_disorder'),
          DELETE: createEndpoint('deletePlateletDisorder', '/api/doctor/v1/phr/doctor/test_result/platelet_disorder')
        },
        MYELOPROLIFERATIVE_NEOPLASM: {
          POST: createEndpoint(
            'createMyeloproliferativeNeoplasm',
            '/api/doctor/v1/phr/doctor/test_result/myeloproliferative_neoplasm'
          ),
          PUT: createEndpoint(
            'updateMyeloproliferativeNeoplasm',
            '/api/doctor/v1/phr/doctor/test_result/myeloproliferative_neoplasm'
          ),
          DELETE: createEndpoint(
            'deleteMyeloproliferativeNeoplasm',
            '/api/doctor/v1/phr/doctor/test_result/myeloproliferative_neoplasm'
          )
        },
        LIVER_FUNCTION_TEST: {
          POST: createEndpoint('createLiverFunctionTest', '/api/doctor/v1/phr/doctor/test_result/liver_function_tests'),
          PUT: createEndpoint('updateLiverFunctionTest', '/api/doctor/v1/phr/doctor/test_result/liver_function_tests'),
          DELETE: createEndpoint(
            'deleteLiverFunctionTest',
            '/api/doctor/v1/phr/doctor/test_result/liver_function_tests'
          )
        },
        IMMUNOLOGY: {
          POST: createEndpoint('createImmunology', '/api/doctor/v1/phr/doctor/test_result/immunologies'),
          PUT: createEndpoint('updateImmunology', '/api/doctor/v1/phr/doctor/test_result/immunologies'),
          DELETE: createEndpoint('deleteImmunology', '/api/doctor/v1/phr/doctor/test_result/immunologies')
        },
        MAMMOGRAM: {
          key: createEndpoint('mammogramResource', '/api/doctor/v1/phr/doctor/test_result/mammogram')
        },
        XRAY: {
          key: createEndpoint('xRayResource', '/api/doctor/v1/phr/doctor/test_result/x_ray')
        },
        CTSCAN: {
          key: createEndpoint('CtScanResource', '/api/doctor/v1/phr/doctor/test_result/ct_scan')
        },
        ULTRASOUND: {
          key: createEndpoint('ultrasoundResource', '/api/doctor/v1/phr/doctor/test_result/ultrasound')
        },
        MRI: {
          key: createEndpoint(
            'magneticResonanceImagingResource',
            '/api/doctor/v1/phr/doctor/test_result/magnetic_resonance_imaging'
          )
        },
        TEST_DETAILS: {
          key: createEndpoint('testDetailsResource', '/api/doctor/v1/phr/doctor/test_result/test_detail')
        },
        ATTACHMENT: {
          GET: createEndpoint('getLabAttachments', '/api/doctor/v1/phr/doctor/test_result/attachment'),
          POST: createEndpoint('addLabAttachment', '/api/doctor/v1/phr/doctor/test_result/attachment'),
          PUT: createEndpoint('updateLabAttachment', '/api/doctor/v1/phr/doctor/test_result/attachment'),
          DELETE: createEndpoint('deleteLabAttachment', '/api/doctor/v1/phr/doctor/test_result/attachment')
        },
        BREAST_BIOPSY: {
          POST: createEndpoint('createBreastBiopsy', '/api/doctor/v1/phr/doctor/test_result/breast_biopsy'),
          PUT: createEndpoint('updateBreastBiopsy', '/api/doctor/v1/phr/doctor/test_result/breast_biopsy'),
          DELETE: createEndpoint('deleteBreastBiopsy', '/api/doctor/v1/phr/doctor/test_result/breast_biopsy')
        },
        BREAST_ULTRASOUND: {
          key: createEndpoint('breastUltrasoundResource', '/api/doctor/v1/phr/doctor/test_result/breast_ultrasound')
        }
      },
      STAGING_FORM: {
        GET_BY_ENCOUNTER: {
          key: createEndpoint('getEncounterStagingForms', '/api/doctor/v1/phr/doctor/staging'),
          parse: (options: RequestFilterParams) =>
            createEndpoint('getEncounterStagingForms', `/api/doctor/v1/phr/doctor/staging${parseParams(options)}`)
        },
        GET_BY_PATIENT_ID: {
          key: createEndpoint('getStagingFormByPatientId', '/api/doctor/v1/phr/doctor/staging/by_patient'),
          parse: (options: RequestFilterParams) =>
            createEndpoint(
              'getStagingFormByPatientId',
              `/api/doctor/v1/phr/doctor/staging/by_patient${parseParams(options)}`
            )
        },
        LIVER: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(`${key}LiverStagingForm`, `/api/doctor/v1/phr/doctor/staging/liver${parseParams(options)}`)
        },
        PANCREATIC: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}PancreaticStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/pancreatic${parseParams(options)}`
            )
        },
        COLORECTAL: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}ColorectalStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/colorectal${parseParams(options)}`
            )
        },
        GALLBLADDER: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}GallbladderStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/gallbladder${parseParams(options)}`
            )
        },
        BREAST: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(`${key}BreastStagingForm`, `/api/doctor/v1/phr/doctor/staging/breast${parseParams(options)}`)
        },
        CERVIX_UTERI: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}CervixUteriStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/cervix_uteri${parseParams(options)}`
            )
        },
        LUNG: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(`${key}LungStagingForm`, `/api/doctor/v1/phr/doctor/staging/lung${parseParams(options)}`)
        },
        PROSTATE: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}ProstateStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/prostate${parseParams(options)}`
            )
        },
        CERVICAL_LYMPH_NODES: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}CervicalLymphNodesStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/cervical_lymph_node${parseParams(options)}`
            )
        },
        ENDOMETRIAL: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}EndometrialStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/corpus_uteri${parseParams(options)}`
            )
        },
        OROPHARYNGEAL: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}OropharyngealStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/oropharyngeal${parseParams(options)}`
            )
        },
        NASOPHARYNGEAL: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}NasopharyngealStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/nasopharyngeal${parseParams(options)}`
            )
        },
        ORAL_CAVITY: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}OralCavityStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/oral_cavity_cancer${parseParams(options)}`
            )
        },
        BRAIN_AND_SPINAL_CORD: {
          parse: (key: string, options?: RequestFilterParams) =>
            createEndpoint(
              `${key}BrainAndSpinalCordStagingForm`,
              `/api/doctor/v1/phr/doctor/staging/brain_and_spinal_cord${parseParams(options)}`
            )
        }
      },
      VACCINATION: {
        key: createEndpoint('savePatientVaccination', '/api/doctor/v1/phr/doctor/vaccination'),
        parse: (options: RequestFilterParams, source?: string) =>
          createEndpoint(
            `get${source ? source : 'Patient'}Vaccination`,
            `/api/doctor/v1/phr/doctor/vaccination${parseParams(options)}`
          )
      },
      ACT_NOW: {
        POST: createEndpoint('updateActNow', '/api/doctor/v1/phr/doctor/type/act_now'),
        GET: createEndpoint('getActNow', '/api/doctor/v1/phr/doctor/type/act_now')
      },
      OPHTHALMOLOGY: {
        POST: createEndpoint('updateOphthalmology', '/api/doctor/v3/phr/type/ophtha'),
        GET: createEndpoint('getOphthalmology', '/api/doctor/v3/phr/type/ophtha')
      },
      OB: {
        POST: createEndpoint('updateOb', '/api/doctor/v1/phr/doctor/type/ob'),
        GET: createEndpoint('getOb', '/api/doctor/v1/phr/doctor/type/ob')
      },
      GYNE: {
        POST: createEndpoint('updateGyne', '/api/doctor/v1/phr/doctor/type/gyne'),
        GET: createEndpoint('getGyne', '/api/doctor/v1/phr/doctor/type/gyne')
      }
    },
    PATIENT_REFERRAL: {
      POST: createEndpoint('referPatient', '/api/doctor/v3/phr/patient_referral'),
      FAVORITES: createEndpoint('referralFavorites', '/api/doctor/v3/phr/patient_referral/favorite ')
    },
    NOTES_AND_FINDINGS_FILES: {
      key: createEndpoint('notesAndFindingsFiles', '/api/doctor/v3/phr/findings_file'),
      parse: (options: RequestFilterParams) =>
        createEndpoint('notesAndFindingsFiles', `/api/doctor/v3/phr/findings_file${parseParams(options)}`)
    }
  },
  SECRETARY: {
    GET: createEndpoint('getSecretary', '/api/doctor/v1/doctor/account/secretary'),
    POST: createEndpoint('addSecretary', '/api/doctor/v1/doctor/account/add_secretary'),
    DELETE: createEndpoint('removeSecretary', '/api/doctor/v1/doctor/account/remove_secretary'),
    DASHBOARD: createEndpoint('getSecretaryDashboard', '/api/doctor/v1/secretary/dashboard'),
    PERMISSION: {
      GET: createEndpoint('getPermission', '/api/doctor/v3/account/permission'),
      POST: createEndpoint('addPermission', '/api/doctor/v3/account/permission'),
      PUT: createEndpoint('updatePermission', '/api/doctor/v3/account/permission')
    },
    MEMBERSHIP: {
      GET: createEndpoint('getMembership', '/api/doctor/v1/doctor/account/membership'),
      PUT: createEndpoint('updateMembership', '/api/doctor/v1/doctor/account/membership')
    }
  },
  NAVIGATOR: {
    DASHBOARD: createEndpoint('getNavigatorDashboard', '/api/doctor/v1/navigator/dashboard')
  },
  CONTACT: {
    NUMBER: {
      ADD: createEndpoint('addContactNumber', '/api/doctor/v1/contact/number'),
      UPDATE: createEndpoint('updateContactNumber', '/api/doctor/v1/contact/number'),
      DELETE: createEndpoint('deleteContactNumber', '/api/doctor/v1/contact/number'),
      GET: {
        key: createEndpoint('getContactNumbers', '/api/doctor/v1/contact/number'),
        parse: (options: RequestFilterParams) =>
          createEndpoint('getContactNumbers', `/api/doctor/v1/contact/number${parseParams(options)}`)
      }
    },
    ADDRESS: {
      ADD: createEndpoint('addContactAddress', '/api/doctor/v1/contact/address'),
      UPDATE: createEndpoint('updateContactAddress', '/api/doctor/v1/contact/address')
    }
  },
  SURVEY: {
    FORMS: {
      parse: (key: string, options?: RequestFilterParams) =>
        createEndpoint(`getSurveys`, `/api/doctor/v3/doctor/survey_form${parseParams(options)}`)
    }
  },
  SUBMISSION: {
    FORMS: createEndpoint('submissionForms', '/api/doctor/v3/submission_forms'),
    BCMAP: {
      parse: (key: string, options?: RequestFilterParams) =>
        createEndpoint(`${key}BCMAP`, `/api/doctor/v3/submission_forms${parseParams(options)}`)
    },
    DRUG_REQUEST: {
      POST: createEndpoint('addDrugRequest', '/api/doctor/v3/submission_forms/submission_form_details/drug_requests'),
      PUT: createEndpoint('updateDrugRequest', '/api/doctor/v3/submission_forms/submission_form_details/drug_requests'),
      DELETE: {
        parse: (options?: RequestFilterParams) =>
          createEndpoint(
            'deleteDrugRequest',
            `/api/doctor/v3/submission_forms/submission_form_details/drug_requests${parseParams(options)}`
          )
      }
    },
    RADIATION_TREATMENT: {
      POST: createEndpoint('addRadiationTreatment', '/api/doctor/v3/submission_forms/submission_form_details'),
      PUT: createEndpoint('updateRadiationTreatmentt', '/api/doctor/v3/submission_forms/submission_form_details'),
      DELETE: {
        parse: (options?: RequestFilterParams) =>
          createEndpoint(
            'deleteRadiationTreatment',
            `/api/doctor/v3/submission_forms/submission_form_details${parseParams(options)}`
          )
      },
      parse: (options?: RequestFilterParams) =>
        createEndpoint(
          'getRadiationTreatment',
          `/api/doctor/v3/submission_forms/submission_form_details${parseParams(options)}`
        )
    },
    FOLLOWUP_ONGOING: {
      POST: createEndpoint(
        'addFollowUpOngoing',
        '/api/doctor/v3/submission_forms/submission_form_details/followup_ongoing_treatments'
      ),
      PUT: createEndpoint(
        'updateFollowUpOngoing',
        '/api/doctor/v3/submission_forms/submission_form_details/followup_ongoing_treatments'
      ),
      DELETE: {
        parse: (options?: RequestFilterParams) =>
          createEndpoint(
            'deleteFollowUpOngoing',
            `/api/doctor/v3/submission_forms/submission_form_details/followup_ongoing_treatments${parseParams(options)}`
          )
      }
    },
    FOLLOWUP_AFTER: {
      POST: createEndpoint(
        'addFollowUpAfter',
        '/api/doctor/v3/submission_forms/submission_form_details/followup_after_treatments'
      ),
      PUT: createEndpoint(
        'updateFollowUpAfter',
        '/api/doctor/v3/submission_forms/submission_form_details/followup_after_treatments'
      ),
      DELETE: {
        parse: (options?: RequestFilterParams) =>
          createEndpoint(
            'deleteFollowUpAfter',
            `/api/doctor/v3/submission_forms/submission_form_details/followup_after_treatments${parseParams(options)}`
          )
      }
    },
    ENROLLMENT: {
      POST: createEndpoint(
        'addEnrollmentForm',
        '/api/doctor/v3/submission_forms/submission_form_details/patient_enrollments'
      ),
      PUT: createEndpoint(
        'updateEnrollmentForm',
        '/api/doctor/v3/submission_forms/submission_form_details/patient_enrollments'
      ),
      DELETE: {
        parse: (options?: RequestFilterParams) =>
          createEndpoint(
            'deleteEnrollment',
            `/api/doctor/v3/submission_forms/submission_form_details/patient_enrollments${parseParams(options)}`
          )
      }
    },
    SUPPORTIVE_PALLIATIVE_CARE: {
      POST: createEndpoint(
        'addSupportivePalliativeCareForm',
        '/api/doctor/v3/submission_forms/submission_form_details/supportive_palliative_cares'
      ),
      PUT: createEndpoint(
        'updateSupportivePalliativeCareForm',
        '/api/doctor/v3/submission_forms/submission_form_details/supportive_palliative_cares'
      ),
      DELETE: {
        parse: (options?: RequestFilterParams) =>
          createEndpoint(
            'deleteSupportivePalliativeCare',
            `/api/doctor/v3/submission_forms/submission_form_details/supportive_palliative_cares${parseParams(options)}`
          )
      }
    },
    GET_LATEST: {
      CMAP_ENROLLMENT: createEndpoint(
        'latestEnrollment',
        '/api/doctor/v3/submission_forms/submission_form_details/latest_patient_enrollment'
      ),
      CMAP_DRUG_REQUEST: createEndpoint(
        'latestDrugRequest',
        '/api/doctor/v3/submission_forms/submission_form_details/latest_drug_request'
      ),
      CMAP_FOLLOW_UP_ONGOING: createEndpoint(
        'latestFollowupOngoing',
        '/api/doctor/v3/submission_forms/submission_form_details/latest_followup_ongoing_treatment'
      ),
      CMAP_FOLLOW_UP_AFTER: createEndpoint(
        'latestFollowupAfter',
        '/api/doctor/v3/submission_forms/submission_form_details/latest_followup_after_treatment'
      )
    },
    CF4: {
      parse: (key: string, options?: RequestFilterParams) =>
        createEndpoint(`${key}CF4`, `/api/doctor/v2/submission_forms/cf4${parseParams(options)}`)
    },
    CF4_DETAIL: {
      parse: (key: string, options?: RequestFilterParams) =>
        createEndpoint(
          `${key}CF4Details`,
          `/api/doctor/v2/submission_forms/cf4_detail/admission_and_results${parseParams(options)}`
        )
    },
    CF4_FILES: {
      parse: (key: string, options?: RequestFilterParams) =>
        createEndpoint(
          `${key}AttachedFiles`,
          `/api/doctor/v2/submission_forms/submission_form_files${parseParams(options)}`
        )
    },
    NALAGEN: {
      FORMS: {
        parse: (key: string, options?: RequestFilterParams) =>
          createEndpoint(`${key}Nalagen`, `/api/doctor/v3/submission_forms/nalagen${parseParams(options)}`)
      },
      TEST_ORDERING_FORMS: createEndpoint(
        'nalagenTestOrderingForms',
        '/api/doctor/v3/submission_forms/submission_form_details/test_ordering_forms'
      ),
      TEST_ORDERING_FORMS_PREFILL: {
        parse: (key: string, options?: RequestFilterParams) =>
          createEndpoint(
            `${key}nalagenTestOrderingFormsPrefill`,
            `/api/doctor/v3/submission_forms/submission_form_details/test_ordering_forms/prefill${parseParams(options)}`
          )
      },
      FOLLOWUP_REPORTS: createEndpoint(
        'nalagenTestOrderingForms',
        '/api/doctor/v3/submission_forms/submission_form_details/followup_reports'
      )
    }
  },
  ASSET: {
    GET: createEndpoint('getAsset(V2)', '/api/doctor/v2/asset')
  },
  V3: {
    SEARCH_DOCTOR: createEndpoint('searchDoctor', '/api/doctor/v3/public/search/doctor'),
    USER: createEndpoint('user', '/api/doctor/v3/user'),
    SCHEDULE_SLOTS: createEndpoint('getScheduleSlot', '/api/doctor/v3/public/clinic/schedule/slot'),
    SCHEDULE_COUNT: createEndpoint('getScheduleSlotsCount', '/api/doctor/v3/public/clinic/schedule/slot/count'),
    CONFIRM_RESCHEDULE: createEndpoint('confirmReschedule', '/api/doctor/v3/public/appointment/confirm_reschedule'),
    CANCEL_RESCHEDULE: createEndpoint('cancelReschedule', '/api/doctor/v3/public/appointment/cancel'),
    FOLLOW_UP_SCHEDULE: {
      key: createEndpoint('followupSchedule', '/api/doctor/v3/appointment/follow_up_schedule'),
      parse: (options: RequestFilterParams) =>
        createEndpoint('followupSchedule', `/api/doctor/v3/appointment/follow_up_schedule${parseParams(options)}`)
    },
    GET_APPOINTMENT: createEndpoint('cancelReschedule', '/api/doctor/v3/public/appointment'),
    COUNT: createEndpoint('getPhrCount', '/api/doctor/v3/phr/count'),
    ACCOUNT: createEndpoint('account', '/api/doctor/v3/account'),
    ACCOUNT_MEMBERSHIP: {
      parse: (options: RequestFilterParams) =>
        createEndpoint('getAccountMembership', `/api/doctor/v3/account/membership${parseParams(options)}`),
      key: createEndpoint('accountMembership', '/api/doctor/v3/account/membership'),
      INVITE: createEndpoint('inviteEmail', '/api/doctor/v3/account/membership/send_email')
    },
    CLINIC: {
      HEADER: {
        key: createEndpoint('clinicHeader', '/api/doctor/v3/clinic/header'),
        parse: (options: RequestFilterParams) =>
          createEndpoint('clinicHeader', `/api/doctor/v3/clinic/header${parseParams(options)}`)
      }
    },
    FEEDBACK: {
      POST: createEndpoint('createFeedback', '/api/doctor/v3/feedback')
    },
    APPOINTMENT: {
      key: createEndpoint('v3Appointments', '/api/doctor/v3/appointment'),
      parse: (options: RequestFilterParams) =>
        createEndpoint('v3Appointments', `/api/doctor/v3/appointment/filter${parseParams(options)}`)
    },
    ATTACHMENT_REPOSITORY: {
      parse: (options: RequestFilterParams) =>
        createEndpoint('v3Attachments', `/api/doctor/v3/phr/attachment/repository${parseParams(options)}`)
    }
  },
  PRINTING: {
    PRINT: createEndpoint('printToPDF', '/print'),
    PPT: createEndpoint('printToPPT', '/ppt'),
    PDF_EMAIL: createEndpoint('sendPDFToEmail', '/api/doctor/v3/phr/send_email')
  },
  DIAGRAMS: {
    GET: {
      parse: (options?: RequestFilterParams) =>
        createEndpoint('getAllDiagrams', `/api/doctor/v3/phr/diagram_data${parseParams(options)}`)
    }
  },
  CLILIC_LOCATION: {
    GET: {
      parse: (is_cspmap?: boolean) =>
        createEndpoint('getAllClinicLocation', `/api/doctor/v3/references/clinic${is_cspmap && '?is_cspmap=true'}`)
    }
  }
};

export const DEFAULT_PAGINATION: Pagination = {
  nextPage: null,
  prevpage: null,
  offset: 0,
  page: 1,
  perPage: 5,
  total: 0,
  totalPage: 1
};
